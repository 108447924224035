import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {APIURL} from '../services/http/api';
import {HttpService} from '../services/http/http.service';
import store from '.';
import {setLoading} from './userSlice';

export interface CartType {
  name: string;
  model_name: string;
  model_id: number;
  price: number;
  image: string;
  id: number;
  user_id: string;
  is_soldout:number;
  is_not_available:number;
  date:string|null;
  assigned_name:any;
  start_date:any;
  tracking_type:any;
  users_names:any;
}

export interface CartState {
  data: CartType[];
  loading: boolean;
  totalProducts: number;
  totalPrice: number;
}

export const getToCart = createAsyncThunk('cart/getCart', async () => {
  console.log('resprespresp23');
  const http = new HttpService();
  const api = APIURL.cart.get;
  try {
    const [err, resp] = await http.get(api);
    console.log('resprespresp2', resp);
    // if (resp) {
    //   store.dispatch(setLoading(false));
    // }

    return resp;
  } catch (error) {}
});

const CartSlice = createSlice({
  name: 'cart',
  initialState: {
    data: [],
    loading: false,
    totalProducts: 0,
    totalPrice: 0,
  } as CartState,
  reducers: {},
  extraReducers: {
    [`${getToCart.pending}`]: (
      state: CartState,
      action: PayloadAction<any>,
    ) => {
      state.loading = true;
    },
    [`${getToCart.fulfilled}`]: (
      state: CartState,
      action: PayloadAction<any>,
    ) => {
      console.log('actionactionaction,action', action.payload.data);
      state.totalProducts = action.payload.data.data.length;
      state.data = action.payload.data.data;
      state.totalPrice = action.payload.data.totalPrice;
      state.loading = false;
    },
    [`${getToCart.rejected}`]: (
      state: CartState,
      action: PayloadAction<any>,
    ) => {
      state.loading = false;
    },
  },
});

export default CartSlice.reducer;
