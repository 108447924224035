// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBlnJ9M_bXXKck16qjoInYIRo0xFp5pPPU",
  authDomain: "champions-4e69d.firebaseapp.com",
  databaseURL: "https://champions-4e69d-default-rtdb.firebaseio.com",
  projectId: "champions-4e69d",
  storageBucket: "champions-4e69d.appspot.com",
  messagingSenderId: "572954821201",
  appId: "1:572954821201:web:39d79e4244a144a3c2ae70",
  measurementId: "G-S5EV259T92"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const auth = getAuth(app);
