import Card from 'react-bootstrap/Card';
import {FaCircle, FaPlay} from 'react-icons/fa';
import MainButton from '../../../UI/main-button/MainButton';
import CancelButton from '../../../UI/cancel-button/CancelButton';
import courseImg from '../style/5218235.jpg';
import '../style/CourseCard.css';
import {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from 'react-router-dom';
import LessonVideo from '../../../pages/lesson/components/lesson-video/LessonVideo';
import {CourseItemProps} from '../interfaces/ICourses';
import {addCart, enrollFree} from '../Courses.service';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import {getToCart} from '../../../store/CartSlice';
import store from '../../../store';
import {useAppSelector, useAppDispatch} from '../../../hooks/hooks';
import {isUserLoggedIn} from '../../../auth/utiles';
import {setLoading} from '../../../store/userSlice';
import Preview from '../../../pages/lesson/components/lesson-video/Preview';
import { colors } from '../../../constants/colors';

function CourseCard({course}: CourseItemProps) {
  const cartData = useAppSelector(state => state.cart.data);
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState<boolean>(false);
  const [enrolled, setEnrolled] = useState<boolean>(false);

  const [showpreview, setShowpreview] = useState<boolean>(false);
  const handleClosePreview = () => setShowpreview(false);
  const handleShowpreview = () => setShowpreview(true);
  const [showSoldOut, setShowSoldOut] = useState<boolean>(false);
  

  const navigate = useNavigate();

  // function handleClick() {
  //   if (!loading) {
  //     setShow(false);
  //     navigate(`/lessons`, {state: {course: course}, replace: true});
  //   }
  // }

  function viewCart() {
    navigate(`/cart`);
  }
  const enroll = async () => {
    // if (!loading) {
    dispatch(setLoading(true));
    try {
      let data: FormData = new FormData();
      data.append('course_id', String(course.id));
      data.append('user_id', '1000');
      let result = await enrollFree(data);
      if (result.response) {
        setEnrolled(true);
      }
      dispatch(setLoading(false));
    } catch (e) {
      console.log({e});
    }
    // }
  };
  const addToCart = async () => {
    if (isLoggedIn) {
      // if (!loading) {
      dispatch(setLoading(true));
      try {
        let data: FormData = new FormData();
        data.append('model_id', String(course.id));
        data.append('model_name', 'Course');
        let result = await addCart(data);
        console.log('resultresultresultresultENROL', result);
        dispatch(setLoading(false));
        store.dispatch(getToCart());
        if (result.response) {
          dispatch(setLoading(false));
        }
      } catch (e) {
        console.log({e});
      }
      // }
    } else {
      navigate(`/login`);
    }
  };

  const existInCart = () => {
    return cartData.find(
      i => i.name == course.title && i.model_id == course.id,
    );
  };

  const [isFixed, setIsFixed] = useState(true);

  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    const footer = document.getElementById('footer');
    const card = document.getElementById('fixed-card');

    const handleScroll = () => {
      if (window.scrollY + window.innerHeight >= footer!.offsetTop) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Card
        className={`list-item-card ${isFixed ? 'fixed' : 'regular'}`}
        id="fixed-card">
        <div
          className="card-img-container"
          onClick={() => course.preview !== null && handleShowpreview()}>
          <div className="card-img-container">
            {course.preview !== null && (
              <div className="overlay">
                <div className="icon-container">
                  <FaPlay className="preview-icon" />
                </div>
              </div>
            )}
            <img
              className="card-img-top"
              alt="courseImg"
              src={course.thumbnail ?? course.image }
              style={{ height: '100%' }}
            />
          </div>
        </div>
        <Card.Body className="course-body">
          <h3 className="card-custom-title mb-3">{course.title}</h3>
          <p className='mb-2 card-custom-price'>
            <span>
              {course.price == 0 ? 'Free' : `${course.price} ${ course.user_currency}`}
            </span>
            {/* <span className="real-price">60$</span>{' '}
            <span className="discount-percentage">30%</span> */}
          </p>

          {course?.is_limited == 1 &&
            !course?.is_enrolled &&
            course?.is_live == 1 && (
          <span
          
          style={{
            background: colors.chamThirdColor,
            padding: '5px 10px',
            borderRadius: '10px',
            color:'#fff',
          }}>
            {
            course?.has_reach_limit == 1 ? `Sold out seats: ${course?.remaining_number} left of ${course?.limited_number} seats` : `Limited seats : ${course?.remaining_number} left of ${course?.limited_number} seats`
          }
            
          </span>
          )}

          <div
            className="mt-4 mb-2"
            onClick={() => {
              if (enrolled) {
                navigate(`/lessons/${course.id}`, {state: {course: course}, replace: true});
              }else if(course?.is_limited == 1 && course?.is_live == 1 && course?.has_reach_limit == 1){
                setShowSoldOut(true)
              }else if (course.price == 0) {
                enroll();
              } else if (existInCart()) {
                navigate(`/cart`);
              } else {
                addToCart();
              }
            }}>
            <MainButton
              text={
                enrolled
                  ? 'Go to course'
                  : course.price == 0
                  ? 'Enroll Course'
                  : existInCart()
                  ? 'View Cart'
                  : 'Add to Cart'
              }
            />
          </div>
        </Card.Body>
      </Card>

      {/*course details pop up*/}
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Course Details</Modal.Title>
        </Modal.Header>
        {loading ? (
          <LoadingSpinner />
        ) : enrolled ? (
          <Modal.Body className="text-center">
            <img src="/icons/enrolled.png" />
            <h3 className="align-text-center mt-3">
              Course Enrolled Successfully
            </h3>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div
              className="pop-up-des"
              style={{
                padding: '2rem',
                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                borderRadius: '10px',
              }}>
              <div className="desc-title-container mb-3">
                <div className="desc-title text-muted">Course Name</div>
                <h5 className="desc-value">{course.title}</h5>
              </div>

              <div className="desc-title-container mb-3">
                <div className="desc-title text-muted">Provided By</div>
                <h5 className="desc-value">{course.provider_data?.name}</h5>
              </div>

              <div className="desc-title-container mb-3">
                <div className="desc-title text-muted">Course Includes</div>
                <div className="course-features">
                  <p>
                    <span>
                      <FaCircle color="#FA5456" style={{fontSize: '12px'}} />{' '}
                    </span>{' '}
                    {course.includes.hours} Total Hours
                  </p>
                  <p>
                    <span>
                      <FaCircle color="#FA5456" style={{fontSize: '12px'}} />{' '}
                    </span>{' '}
                    {course.includes.quiz} Quiz
                  </p>
                  <p>
                    <span>
                      <FaCircle color="#FA5456" style={{fontSize: '12px'}} />{' '}
                    </span>{' '}
                    {course.includes.assessment} Assesmsnts
                  </p>
                  {course.includes.certificate && (
                    <p>
                      <span>
                        <FaCircle color="#FA5456" style={{fontSize: '12px'}} />{' '}
                      </span>{' '}
                      Certificate
                    </p>
                  )}
                  <p>
                    <span>
                      <FaCircle color="#FA5456" style={{fontSize: '12px'}} />{' '}
                    </span>{' '}
                    {course.includes.files} Files
                  </p>
                </div>
              </div>

              <div className="desc-title-container">
                <div className="desc-title text-muted">Course Price</div>
                <h5 className="desc-value">
                  {course.price == 0 ? 'Free' : `${course.price}$`}
                </h5>
              </div>
            </div>
          </Modal.Body>
        )}
        {!enrolled && (
          <Modal.Footer>
            <div
              onClick={() => {
                if (course.price == 0) {
                  enroll();
                } else if (existInCart()) {
                  navigate(`/cart`);
                } else {
                  addToCart();
                }
              }}
              className="w-100">
              <MainButton
                text={
                  course.price == 0
                    ? 'Enroll Course'
                    : existInCart()
                    ? 'View Cart'
                    : 'Add to Cart'
                }
              />
            </div>

            <div onClick={handleClose} className="w-100">
              <CancelButton text="Cancel" />
            </div>
          </Modal.Footer>
        )}
        {enrolled && (
          <Modal.Footer>
            <div onClick={handleClick} className="w-100">
              <MainButton text="Go To Course" />
            </div>
          </Modal.Footer>
        )}
      </Modal> */}

      {/*course preview pop up*/}
      <Modal show={showpreview} onHide={handleClosePreview} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Course Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginTop: '-1rem'}}>
            <Preview url={course.preview} />
          </div>
        </Modal.Body>
      </Modal>

      {/*course sold out*/}
      <Modal show={showSoldOut} onHide={() => setShowSoldOut(false)} size="sm" centered>
       
        <Modal.Body>
          <div className='text-center pt-4 pb-4'>
            <h4>Sorry, All seats are fully booked</h4>
            <div style={{ width: 'fit-content' , margin: 'auto' }} onClick={() => setShowSoldOut(false)}>
              <MainButton text={`OK`} />
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
}

export default CourseCard;
