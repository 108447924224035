import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

import {useEffect, useState} from 'react';
import { getUserEvents } from '../Events/Events.service';
import {useTranslation} from 'react-i18next';
import { setLoading } from '../../store/userSlice';
import { useAppDispatch } from '../../hooks/hooks';
import EventCard from '../Events/components/EventCard';
import ItemsPagination from '../../UI/pagination/ItemsPagination';

const UserEvents = () => {

  const {t} = useTranslation();

  const dispatch = useAppDispatch();
  
  const navigate = useNavigate();
  const [data, setDate] = useState([]);
  const [lastPage, SetLastPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  
  
  useEffect(() => {
    getUserEventsList()
  }, [page]);
  

  const getUserEventsList = async () => {
    dispatch(setLoading(true));
    let data = new FormData();
    let result = await getUserEvents(page);
    if (result?.response?.status) {
      dispatch(setLoading(false));
      console.log('user events' , result?.response?.data )
      setDate(result?.response?.data.data);
      SetLastPage(result.response.data.last_page);
    }else{
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      <Container>
        <Row className="d-flex">
        {data.map((a: any, i) => {
            return (
              <Col md="6" className="mt-4" key={i} onClick={() => navigate(`/event/${a.event_id}/details?userEnrolled=1`) } >
                <EventCard 
                eventItem={
                  {...a , is_enrolled : 1}
                }
                />
              </Col>
            );
          })}
          {data.length ? 
          <Col lg={12}>
            <div className="pagination-container">
              <ItemsPagination
                pages={lastPage}
                current={page}
                changePage={page => {
                  setPage(page);
                }}
              />
            </div>
            </Col>
            : ''}
        </Row>
      </Container>
    </div>
  );
};

export default UserEvents;
