import classes from './spinner.module.css';
const LoadingSpinner = () => {
  return (
    <div className={`${classes.Container}`}>
      <img className={`${classes.LoadingSpinner}`} src='/champ-logo.png' />
    </div>
  );
};

export default LoadingSpinner;
