import {Col, Container, Dropdown, Modal, Row} from 'react-bootstrap';
import classes from './style/Lesson.module.css';
import LessonVideo from '../../pages/lesson/components/lesson-video/LessonVideo';
import LessonTabs from './components/LessonTabs';
import MainButton from '../../UI/main-button/MainButton';
import ActionButton from '../../UI/action-button/ActionButton';
import {FaShareAlt, FaStar} from 'react-icons/fa';
import CardProgress from '../../UI/card-progress/CardProgress';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {
  CourseType,
  FeedBack,
  Lesson as LessonProp,
} from './interfaces/ICourses';
import {
  finishLesson,
  getCourseDetails,
  getFeedBacks,
  sendReview,
  seenCongratsScreen,
  getQuestionsReviewCourse,
  sendQestionsReview,
} from './Courses.service';
import {Collapse} from 'react-bootstrap';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {CgSandClock} from 'react-icons/cg';
import toast, {Toaster} from 'react-hot-toast';
import {colors} from '../../constants/colors';
import {JoinRoom} from '../Consultants/Consultants.service';
import moment from 'moment';
import ShareIcon from '../../components/share-icon/ShareIcon';
import RateStarsEdit from '../../UI/rate-stars-edit/RateStarsEdit';
import Form from 'react-bootstrap/Form';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';

const Lesson = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {id} = useParams();
  const dispatch = useAppDispatch();

  const [item, setItem] = useState<CourseType>();
  const [showVideo, setshowVideo] = useState<Boolean>(false);
  const [feedBacks, setFeedBacks] = useState<FeedBack[]>([]);
  const [collapsed, setCollapsed] = useState<number | null>(null);
  const [url, setUrl] = useState<string>('');
  const [coureId, setCoureId] = useState<string>(id!);
  const [showCongrats, setShowCongrats] = useState(false);
  const [showRate, setShowRate] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState('');
  const [surveyItems, setSurveyItems] = useState([]);
  const [editedSurveyItems, setEditedSurveyItems] = useState([]);

  const Seen = async () => {
    await seenCongratsScreen(coureId);
  };

  const changeCollapeHandler = (index: number) => {
    if (collapsed == index) {
      setCollapsed(null);
    } else {
      setCollapsed(index);
    }
  };
  useEffect(() => {
    dispatch(setLoading(true));
    getData(coureId);
    //getFeedBacksFN();
    getQuestionsReviewCourseFN();
  }, []);

  // get qestions for afer course finish
  const getQuestionsReviewCourseFN = async () => {
    let result = await getQuestionsReviewCourse(coureId);
    if (result.response) {
      setSurveyItems(result.response.data.questionnaire_questions);
      console.log('get survey', result.response.data);
    }
  };

  const changeQuestionRate = (e: any, i: any) => {
    console.log(e, i, editedSurveyItems);
    let items: any = surveyItems;
    items[i].rateValue = e;
    console.log('items', items);
    setEditedSurveyItems(items);
  };

  const setSurvey = async () => {
    dispatch(setLoading(true));

    try {
      let data = new FormData();
      console.log('editedSurveyItems', editedSurveyItems);
      editedSurveyItems?.map((i: any, key) => {
        data.append(
          `questionnaires[${key}][questionnaire_id]`,
          i?.questionnaire_id,
        );
        data.append(`questionnaires[${key}][questionnaire_question_id]`, i?.id);
        data.append(`questionnaires[${key}][rate]`, i?.rateValue ?? 0);
      });
      data.append(`type_id`, `1`);
      data.append(`type`, 'Course');
      let result = await sendQestionsReview(data);
      console.log('resultssss', result);

      if (result.response) {
        dispatch(setLoading(false));
        setShowSurvey(false);
        window.location.reload();
      }else{
        
        toast.error(result.error.response.data.message);
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };

  const comparedays = (date: string, i: LessonProp) => {
    let tt = date?.includes('T')
      ? date?.replace('T', 'T').slice(0, date.indexOf('.'))
      : date?.replace(' ', 'T').slice(0, date.indexOf('.'));
    let newend = tt?.replace(i?.start_time!, i?.end_time!);
    let now = moment(new Date());

    let t = new Date();
    let e = new Date(tt);

    let eend = new Date(newend);
    let x = now?.diff(tt, 'minutes');
    let StartSession = x + 10;
    let y = now.diff(newend, 'minutes');

    if (StartSession <= 0) return 'before';
    else if (StartSession >= 0 && now?.diff(newend) < 0) return 'start';
    else return 'exp';
  };

  const navigateLesson = async (item: LessonProp) => {
    if (item?.content_type == 'Quizze') {
      navigate(`/quiz/${item.quizze_id}`, {
        state: {lessonId: item.id, title: item.title},
      });
    }
    // }
    else if (
      item?.content_type == 'PracticalTask' &&
      !item?.pending &&
      !item?.finished
    ) {
      navigate('/practical-task-upload', {
        state: {item: item},
      });
    } else if (item?.content_type == 'PracticalTask') {
      if (item?.finished) {
        toast.success('task Accepted');
      } else {
        toast.error('task Pending');
      }
    } else if (item?.content_type == 'video' || item?.content_type == 'record' ) {
      setUrl(item?.attachments[0]?.aws_path);
      setshowVideo(true);
      endlesson(item?.id, false);
    } else if (item?.content_type == 'pdf') {
      endlesson(item.id, false);
    } else if (item?.content_type == 'meet') {
      if (comparedays(item?.start_date_session, item) == 'start') {
        endlesson(item?.id, false);
        let name = localStorage.getItem('name') ?? '';
        let image =
          localStorage.getItem('avatar') != 'null'
            ? localStorage.getItem('avatar')
            : 'http://example.com';
        console.log('image', image);

        let id = localStorage.getItem('id');

        if( item?.has_private_link != 0 ){
          window.location.href = item?.consultant_meeting_link
        }else{
          let meet = await JoinRoom(
            name.toString(),
            'Participante1',
            item?.meeting_id!,
            image!,
            id!,
          );
          if (meet?.data?.token) {
            console.log('me', meet);
            navigate(`/meet/${meet.data.token}`);
          }
        }
      } else if (comparedays(item?.start_date_session, item) == 'exp') {
        toast.error('Meet Expired');
      } else if (comparedays(item?.start_date_session, item) == 'before') {
        toast.error('Meet Not Start');
      }
    }
  };

  /*
  const getFeedBacksFN = async () => {
    let result = await getFeedBacks(coureId);
    if (result?.response) {
      setFeedBacks(result.response.data);
    }
  };
  */

  const getData = async (id: string) => {
    let result = await getCourseDetails(coureId);
    if (result.response.data) {
      if( result.response.data.is_enrolled ){
        setItem(result.response.data);

        console.log('result.response.data', result.response.data.id);
        dispatch(setLoading(false));
        let dateResponse = result.response.data;
  
        if (dateResponse.progress == 100 && !dateResponse.congrats_seen) {
          setShowCongrats(true);
          Seen();
        }
      }else{
        navigate('/courses')
      }
      
    }
  };
  const endlesson = async (id: number | string, live: boolean) => {
    let data = new FormData();
    data.append(`lesson_id`, String(id));
    let result = await finishLesson(data);
    if (result.response && !live) {
      dispatch(setLoading(true));
      getData(coureId);
    }
  };

  const addRate = async () => {
    dispatch(setLoading(true));

    try {
      let data = new FormData();

      data.append('course_id', coureId);
      data.append('rating', String(rate));
      data.append('comment', String(comment));

      let result = await sendReview(data);
      console.log('resultssss', result);

      if (result.response) {
        dispatch(setLoading(false));
        setShowRate(false);
        if (surveyItems.length > 0) {
          setShowSurvey(true);
        } else {
          window.location.reload();
        }
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.log({e});
    }
  };

  return (
    <div>
      <Container className={`${classes.lessonContainer}`}>
        <Toaster position="top-right" />
        <Row className="d-flex justify-content-between">
          <Col xs={{span: 12, order: 1}} md={{span: 8, order: 1}}>
            <div
              className={`${classes.lessonTopHeader} d-flex justify-content-between align-items-center`}>
              <div className={`${classes.lessonTitle}`}>
                <h4>{item?.title}</h4>
              </div>

              <div className="user-actions d-flex">
                {item?.progress == 100 && !item?.is_rated && (
                  <div className='rate-course-button' style={{ cursor : 'pointer' }} onClick={() => setShowRate(true)}>
                    <FaStar className='mx-1' /> Rate Course
                  </div>
                )}

                {/*<ShareIcon url={null} />*/}
              </div>
            </div>
            {showVideo ? (
              <LessonVideo url={url} lessonId={item?.id} />
            ) : (
              <div
                style={{height: '400px', overflow: 'hidden', padding: '20px'}}>
                <img
                  src={item?.image ?? ''}
                  alt="preview"
                  style={{width: '100%'}}
                />
              </div>
            )}
            <div>
              <div
                className={`${classes.cardProgressContainer} p-md-5 text-center`}>
                <CardProgress progress={item?.progress} />
              </div>

              <LessonTabs
                id={coureId}
                subscribers={item?.subscribers!}
                description={item?.description!}
                ratings={item?.ratings!}
                rating_stars={item?.rating_stars!}
              />
            </div>
          </Col>

          <Col xs={{span: 12, order: 2}} md={{span: 4, order: 1}}>
            <h3 style={{marginTop: 10}}>lectures</h3>
            <div className="course-content-sections">
              {item?.sections?.map((section, index) => {
                return (
                  <div className="section-container mb-4" key={index}>
                    <div
                      onClick={() => changeCollapeHandler(index)}
                      aria-controls="example-collapse-text"
                      aria-expanded={collapsed == index}
                      className="d-flex justify-content-between align-items-center section-item-collapse">
                      <p className="section-title">{section.title}</p>
                      <div>{collapsed == index ? <FaMinus /> : <FaPlus />}</div>
                    </div>
                    <Collapse in={collapsed == index}>
                      <div id="example-collapse-text">
                        {section?.lessons?.map((lesson, indexs) => {
                          let type = lesson.content_type;
                          return (
                            <div
                              onClick={() => navigateLesson(lesson)}
                              className="section-item d-flex justify-content-between"
                              key={indexs}>
                              <div className="icons-container d-flex">
                                <div>
                                  {(lesson.content_type === 'video' || lesson.content_type == 'record' ) && (
                                    <img alt="img" src="/icons/video.png" />
                                  )}
                                  {lesson.content_type === 'meet' && (
                                    <img
                                      alt="img"
                                      src="/icons/zoom.png"
                                      className="main-icon"
                                    />
                                  )}

                                  {lesson.content_type == 'Quizze' && (
                                    <img alt="img" src="/icons/quiz.png" />
                                  )}
                                  {lesson.content_type === 'PracticalTask' && (
                                    <img alt="img" src="/icons/task.png" />
                                  )}
                                  {lesson.content_type === 'pdf' && (
                                    <a
                                      href={lesson?.attachments[0]?.aws_path}
                                      target="_blank"
                                      rel="noreferrer">
                                      <img alt="img" src="/icons/pdf.png" />
                                    </a>
                                  )}
                                </div>

                                <div>
                                  <p className="lecture-title">
                                    {lesson.content_type !== 'pdf' && (
                                      <p>{lesson.title}</p>
                                    )}
                                    {lesson.content_type === 'pdf' && (
                                      <a
                                        href={lesson?.attachments[0]?.aws_path}
                                        target="_blank"
                                        rel="noreferrer">
                                        {lesson.title}
                                      </a>
                                    )}
                                  </p>

                                  <p className="lecture-type">
                                    {(lesson.content_type === 'video' || lesson.content_type == 'record' ) &&
                                      `${lesson.content_type} , ${lesson.duration} Mins`}

                                    {lesson.content_type === 'meet' && `Meet`}

                                    {lesson.content_type === 'Quizze' &&
                                      `Quiz , ${lesson.content_type} Questions`}

                                    {lesson.content_type === 'PracticalTask' &&
                                      `Upload Practical Task`}

                                    <a
                                      href={lesson?.attachments[0]?.aws_path}
                                      target="_blank"
                                      rel="noreferrer">
                                      {lesson.content_type === 'pdf' &&
                                        `Click To Download PDF`}
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                {lesson.content_type == 'meet' && (
                                  <div className="d-flex align-items-center">
                                    {/* <FaLock className='lock-icon' /> */}
                                    <div
                                      style={{
                                        margin: '0 15px',
                                        color: '#b0b0b0',
                                      }}>
                                      <div className="mb-1">
                                        <img
                                          src={'/icons/calendar2.png'}
                                          alt="course-img"
                                          style={{
                                            width: '15px',
                                            marginRight: '10px',
                                          }}
                                        />
                                        {`${moment(
                                          lesson?.start_date_session,
                                        ).format('YYYY-MM-DD')}`}
                                      </div>

                                      <div>
                                        <img
                                          src={'/icons/clock.png'}
                                          alt="course-img"
                                          style={{
                                            width: '15px',
                                            marginRight: '10px',
                                          }}
                                        />

                                        {`${moment(
                                          lesson?.start_time,
                                          'HH:mm:ss',
                                        ).format('hh:mm A')} - ${moment(
                                          lesson?.end_time,
                                          'HH:mm:ss',
                                        ).format('hh:mm A')}`}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {lesson.finished && (
                                  <div>
                                    <img
                                      alt="img"
                                      src="/icons/end.png"
                                      width={25}
                                    />
                                  </div>
                                )}
                                {lesson.pending &&
                                  lesson?.content_type == 'PracticalTask' && (
                                    <div>
                                      <CgSandClock
                                        color={colors.orange}
                                        size={30}
                                      />
                                    </div>
                                  )}
                                {lesson?.answered &&
                                  !lesson?.finished &&
                                  !lesson.pending &&
                                  lesson?.content_type == 'PracticalTask' && (
                                    <div>
                                      <img
                                        alt="img"
                                        src="/icons/failed.png"
                                        width={25}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Collapse>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showCongrats} onHide={() => setShowCongrats(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center p-4">
            <img
              src="/congrats.png"
              className="m-auto"
              style={{width: '330px'}}
            />
            <h3 className="mt-3">Congratulations</h3>
            <p style={{color: 'gray', fontSize: '20px', marginBottom: '10px'}}>
              You Passed this course successfully
            </p>
            <div
              onClick={() => {
                setShowCongrats(false);
                setShowRate(true);
              }}>
              <MainButton text={'Rate this course'} />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showRate} onHide={() => setShowRate(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center p-4">
            <img
              src="/review.png"
              className="m-auto"
              style={{width: '100px'}}
            />
            <h5>Review Session</h5>
            <RateStarsEdit
              ratingChanged={(e: any) => {
                setRate(e);
              }}
            />
            <p style={{color: 'gray'}}>Leave FeedBack</p>
            <Form.Control
              className={`form-control mb-4 mt-2 ${classes.TextArea}`}
              as="textarea"
              rows={3}
              onChange={e => setComment(e.target.value)}
              placeholder="Write your commnet"
            />
            <div
              onClick={() => {
                addRate();
              }}>
              <MainButton text={'Send'} disabled={rate == 0} />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSurvey} onHide={() => setShowSurvey(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="text-center pt-2 p-4">
            <div className="mb-3">
              <img src="/logo.png" width={60} />
            </div>

            {surveyItems.length &&
              surveyItems.map((i: any, index) => {
                return (
                  <div>
                    {i.question}
                    <RateStarsEdit
                      ratingChanged={(e: any) => {
                        changeQuestionRate(e, index);
                      }}
                    />
                  </div>
                );
              })}

            <div
              onClick={() => {
                setSurvey();
              }}>
              <MainButton text={'Submit Result'} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Lesson;
