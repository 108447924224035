import { Col, Container, Row } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classes from './style/PrivateTrackingRequest.module.css'
import { useEffect, useState } from "react";
import MainButton from "../../UI/main-button/MainButton";
import CustomCheckbox from "../Tracking/components/CustomCheckbox";
import { colors } from "../../constants/colors";
import { useParams } from "react-router-dom";
import { getAllRequests, getConsultantsRequest, sendRequest } from "./Trackings.service";
import { useAppDispatch } from "../../hooks/hooks";
import { setLoading } from "../../store/userSlice";
const PrivateTrackingRequest = () => {

    const dispatch = useAppDispatch();

    const [show, setShow] = useState<boolean>(false);

    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const handleCheckboxChange = (event:any) => {
    const { value, checked } = event.target;
    console.log('value, checked', value, checked )
    if (checked) {
        // If checkbox is checked, add the value to the selectedValues array
        setSelectedValues((prevSelectedValues) => [...prevSelectedValues, value]);
    } else {
        // If checkbox is unchecked, remove the value from the selectedValues array
        setSelectedValues((prevSelectedValues) =>
        prevSelectedValues.filter((item) => item !== value)
        );
    }

    console.log('selectedValues' , selectedValues )
    //const selectedObjects = checkboxes.filter(obj => selectedValues.includes(obj.value));
    //console.log('selectedObjects' , selectedObjects )

    };

    const { id , userId , username } = useParams()

    const [consultants, setConsultants] = useState([]);

    const Request = async (consultantId:any) => {
        dispatch(setLoading(true));
        
        
        let data = new FormData();
        data.append('tracking_id', String(id));
        data.append('user_id', String(userId));
        data.append(`consultants[0]`, String(consultantId));
        let result = await sendRequest(data);
        console.log('resultRequest', result);
        if (result?.response) {
            dispatch(setLoading(true));
          // Navigation.back();
    
          getRequests();
          getConsultants();
        }
      };

    
    const getConsultants = async () => {
        dispatch(setLoading(true));
        let result = await getConsultantsRequest(userId, id);
        if (result.response) {
            setConsultants(result.response.data);
            dispatch(setLoading(false));
        }
    };

    const [consultantRqeustsList, setConsultantRqeustsList] = useState([]);

    const getRequests = async () => {
        dispatch(setLoading(true));
       
        
        
        let result = await getAllRequests(userId, id);
        
        if (result.response) {
          dispatch(setLoading(false));
          console.log('resultgetRequests ,' ,result.response.data.data )
          setConsultantRqeustsList(result.response.data.data)
        }
      };

    useEffect(() => {
        getConsultants();
        getRequests();
      }, []);
   
  return (
  
        <Container className="mt-5">
            <Row>
                <Col md={{span: 6 , offset :3}} >
                    <div className="position-relative"
                    style={{
                        border: '1px solid #cfcfcf',
                        borderRadius: '15px',
                        padding: '40px 30px 10px 10px',
                        color:colors.maincolor,
                        fontWeight:'bold',
                        marginBottom:'10px'
                    }}
                    >
                    <span
                    style={{
                        background: colors.maincolor,
                        borderRadius: '0px 10px',
                        color:'#fff',
                        padding: '5px 20px',
                        right: '0',
                        position:'absolute',
                        top:'0'
                    }}
                    >note</span>    
                    <p style={{ fontSize: '18px'}} className="mb-1">
                    - Here you can send a request to one or more consultants to monitor your system
                    </p>
                    <p style={{ fontSize: '18px'}} className="mb-1">
                    - You can only choose a consultant from the list of consultants with whom you have previously interacted, either directly or through one of the family members, for example, having interacted with them through purchasing a previous session, training course, or a monitoring system
                    </p>
                    </div>

                    
                    <h4 className="secondry-color mb-4 mt-4">Me</h4>
                    <div className="box-border mb-4">
                        <div className="d-flex justify-content-between cursor-pointer" onClick={ () => setShow(!show) }>
                            <h5>Consultants List</h5>
                            <div>
                                <img
                                src='/icons/down-arrow.png' 
                                style={{ 
                                    width: '15px',
                                    transform: show ? 'rotate(180deg)' : 'rotate(00deg)'
                                }} 
                                />
                            </div>

                        </div>
                        <hr style={{ paddingBottom: '1rem' }} />
                        { show && <div>
                            {
                                consultants.map((item:any,i) => (
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <img 
                                        src={item.avatar ?? '/icons/manHolder.png' }
                                        alt='image'
                                        style={{
                                            width: '60px',
                                            borderRadius: '50%',
                                            height: '60px',
                                            margin:'0 20px 0 0',
                                        }}
                                        />
                                        <span>{item.username}</span>
                                    </div>

                                    <div>


                                    <div>
                                        <div onClick={ () => { Request(item.id) } }>
                                            <MainButton text={'send'} />
                                        </div>
                                    </div>

                                    

                                    </div>    
                                </div>
                                ))
                            }
                            </div>
                        }   
                    </div>

                    
                    <div>
                        <h4 className="secondry-color mb-4">Requests Status</h4>
                        <Tabs
                        defaultActiveKey="pending"
                        id="uncontrolled-tab-example"
                        className="custom-web-tabs mb-3"
                        >

                            <Tab eventKey="pending" title={<span>Pending <span className={`secondry-color ${classes.TabTitleNumber}`}>
                            {consultantRqeustsList.filter((obj:any) => obj.status == 0).length}</span> </span>}>
                            {
                               consultantRqeustsList.map((item:any,i) => (
                                    
                                        <div>
                                            { item.status == 0 ? 
                                            <div className="mb-3">
                                                <img 
                                                src={ item.consultant_avatar  ?? '/icons/manHolder.png' }
                                                alt='image'
                                                style={{
                                                    width: '60px',
                                                    borderRadius: '50%',
                                                    height: '60px',
                                                    margin:'0 20px 0 0',
                                                }}
                                                />
                                                <span>{ item.consultant_name }</span>
                                            </div>
                                            : '' }
                                        </div>
                                    
                               ))
                            }

                            </Tab>

                            <Tab eventKey="accepted" title={<span>Accepted <span className={`secondry-color ${classes.TabTitleNumber}`}>
                            {consultantRqeustsList.filter((obj:any) => obj.status == 1).length}</span> </span>}>  
                            {
                               consultantRqeustsList.map((item:any,i) => (
                                    
                                        <div>
                                            { item.status == 1 ? 
                                            <div className="mb-3">
                                                <img 
                                                src={ item.consultant_avatar  ?? '/icons/manHolder.png' }
                                                alt='image'
                                                style={{
                                                    width: '60px',
                                                    borderRadius: '50%',
                                                    height: '60px',
                                                    margin:'0 20px 0 0',
                                                }}
                                                />
                                                <span>{ item.consultant_name }</span>
                                            </div>
                                            : '' }
                                        </div>
                                    
                               ))
                            }  
                            </Tab>

                            <Tab eventKey="rejected" title={<span>Rejected <span className={`secondry-color ${classes.TabTitleNumber}`}>
                            {consultantRqeustsList.filter((obj:any) => obj.status == 2).length}</span> </span>}>  
                            {
                               consultantRqeustsList.map((item:any,i) => (
                                    
                                        <div>
                                            { item.status == 2 ? 
                                            <div className="mb-3">
                                                <img 
                                                src={ item.consultant_avatar  ?? '/icons/manHolder.png' }
                                                alt='image'
                                                style={{
                                                    width: '60px',
                                                    borderRadius: '50%',
                                                    height: '60px',
                                                    margin:'0 20px 0 0',
                                                }}
                                                />
                                                <span>{ item.consultant_name }</span>
                                            </div>
                                            : '' }
                                        </div>
                                    
                               ))
                            }  
                            </Tab>
                
                
                        </Tabs>
                    </div>
                    

                    

                </Col>
            </Row>
        </Container>
    
  );
}

export default PrivateTrackingRequest;