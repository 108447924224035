import {APIURL} from '../../services/http/api';
import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';
import {HandleAPIParams} from '../../helpers/HandleAPIParams';

const http = new HttpService();


export const getEventsList = async (
  page: number | string,
  title: string,
  languages: number[] | null,
  followUpTypes: number[] | null,
  providerSelected: number[] | null,
  priceSelected: number[] | null,
) => {
  const api = APIURL.events.getEventsList;
  let finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
  });

  let data: {[key: string]: any} = {};

  if( title != '' ){
    data['search'] = title;
  }

  languages?.length &&
    languages.map((i, index) => {
      data[`langs[${index}]`] = i;
    });

  followUpTypes?.length &&
    followUpTypes.map((i, index) => {
      data[`level_types[${index}]`] = i;
    });

  providerSelected?.length &&
    providerSelected.map((i, index) => {
      data[`providers[${index}]`] = i;
    });

  priceSelected?.length &&
    priceSelected.map((i, index) => {
      data[`price_types[${index}]`] = i;
    });

  finalApi = HandleAPIParams(finalApi + '&', data);

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getEventDetails = async (id: number | string) => {
  const api = APIURL.events.eventDetails;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getmemberForAssign = async (trackId: any) => {
  const api = APIURL.events.getMembers;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: trackId,
  });

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const enrollFree = async (data: FormData) => {
  const api = APIURL.events.enrollFree;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getUserEvents = async (page: number | string) => {
  const api = APIURL.events.getUserEvents;
  let finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};


  
};