import Card from 'react-bootstrap/Card';
import './InitiativeCard.css';
import {useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import { Button } from 'react-bootstrap';

function FollowupCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    if( props.isPackage ){
      if( props.selected_enrolled_user_id ){
        window.location.href= `/user/trackings/${props.id}/package/levels?discover=0`
      }else{
        window.location.href= `/user/trackings/${props.id}/package/levels/?discover=1`
      }
    }else{
      if( (props.tracking_type && props.tracking_type == 'single') || props.level ){

        if( props.selected_enrolled_user_id ){
          window.location.href= `/user/trackings/${props.id}/details/enrolled/${props.selected_enrolled_user_id}`
        }else{
          window.location.href= `/user/tracking/${props.id}/details`
        }
      }
    }
  }
  return (
    <Card className="initiative-card home-custom-card position-relative" onClick={handleClick}>
      <p className='top-card-label'>
        <span className='price-label'>
        { props.has_unlimited_price ? t('start from ') : '' }{props.price ? ((props.price + ' ' + t(props.user_currency)) ?? ' USD') : t('Free') }
        </span>

        {/*
        <span className='subscribed-label'>
          Subscribed for other
        </span>
        */}

      </p>
      <Card.Img variant="top" src={ props.image ? props.image : '/initiative/initiative.jpg' } />
      <Card.Body className="mt-3">
        <Card.Title className="card-custom-title">
        {props.name.length > 25 ? props.name.slice(0,25) + ' ...' : props.name }
        </Card.Title>
        <Card.Text className="card-custom-provider">{props.provider_name}</Card.Text>

        <Card.Text style={{color: '#FA5456' , fontSize: '18px' , fontWeight: '700'}}>

        { props.isPackage ? props.level_count + ' Levels' : props.duration + ' Days' }
        </Card.Text>

        <div onClick={handleClick}>
            
          <Button className="card-custom-button">
                  {t('Join Follow-up System')}
          </Button>
        </div>
       
      </Card.Body>
    </Card>
  );
}

export default FollowupCard;
