

import moment from "moment";
import toast, {Toaster} from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import classes from '../style/TrackingDetailsAfterEnroll.module.css';
import practicalTaskClasses from '../../Courses/style/PracticalTaskUplaod.module.css';
import { t } from 'i18next';
import { ProgressBar } from "react-bootstrap";
import { FaPaperclip, FaRegCheckSquare } from "react-icons/fa";
import { colors } from '../../../constants/colors';
import axios from "axios";
import { getDomain } from "../../../helpers/getDomain";
import { useAppDispatch } from "../../../hooks/hooks";
import { setLoading } from "../../../store/userSlice";
import ZeroProgressPopover from "./ZeroProgressPopover";
import { removeActivityProof } from "../../Tracking/Trackings.service";

const ProofItem = ({
  daySelected ,
  today,
  hasHistory,
  proof,
  index,
  user_activity_id,
  actId,
  getData,
  dateType,
  trackType,
  showMsgPopup

}:any) => {
  

  const [proofId, setProofId] = useState<any>(null);
  
  const [proofType, setProofType] = useState<any>('link');

  const [linkName, setLinkName] = useState<any>(null);

  const [profMidea, setprofMidea] = useState<any>();

  const [progressVal, setProgressVal] = useState<any>(1);

  const [showProgressVal, setShowProgressVal] = useState<any>(false);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const dispatch = useAppDispatch();

  const handleFileChange = async (event: any) => {
    
    setShowProgressVal(true)
    dispatch(setLoading(true));
    const file = event.target.files[0];
    setSelectedFile(file);

    let data = new FormData();
    data.append('user_activity_id', user_activity_id);
    data.append('activity_id', String(actId));
    data.append('progress', '100');
    data.append('media_id', String(proofId));
    const fileExtension = file.name.split('.').pop();


    if (
      ['png','PNG','jpg','JPG','jpeg','JPEG','webp','WEBP','svg','SVG'].includes(fileExtension)
    ) {
      data.append('prof_type', 'photo');
    }else if (
      ['mp4','MP4','mpg','mpeg','avi','wmv','mov','rm','ram','OGG','ogg','webm','WEBM'].includes(fileExtension)
    ) {
      data.append('prof_type', 'video');
    }
    
    else {
      data.append('prof_type', 'decoment');
    }

    data.append('file',  file);


    let token = localStorage.getItem('token');
    try {
      let result = await axios.post(getDomain() + '/api/trackingsystem/activities/updateProgressMedia', data , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: function(progressEvent:any) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          
          setProgressVal(percentCompleted)

        }
      });
      console.log('dsadsadafdata', result.data.data);

      if (result.data.status) {
        if( result.data.data == '100' && dateType == 'date_range' && trackType != null ){
          if( showMsgPopup ) showMsgPopup();
        }
        setShowProgressVal(false)
        setProgressVal(1)
        if( getData ) getData();
      }
    } catch (e) {
      dispatch(setLoading(false));
      showProgressVal(1)
      console.log(e);
    }
  };

  const sendProofLink = async (proofId:any) => {
    
    setShowProgressVal(true)
    dispatch(setLoading(true));
   

    let data = new FormData();
    data.append('user_activity_id', user_activity_id);
    data.append('activity_id', String(actId));
    data.append('progress', '100');
    data.append('media_id', String(proofId));
   
    
    data.append('prof_type', 'link');

    data.append('file', linkName );


    let token = localStorage.getItem('token');
    try {
      let result = await axios.post(getDomain() + '/api/trackingsystem/activities/updateProgressMedia', data , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: function(progressEvent:any) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          
          setProgressVal(percentCompleted)

        }
      });
      console.log('dsadsadafdata', result.data.data);

      if (result.data.status) {
        setShowProgressVal(false)
        setProgressVal(1)
        getData()
      }
    } catch (e) {
      dispatch(setLoading(false));
      showProgressVal(0)
      console.log(e);
    }
  };

  const removeProf = async (id:any) => {
    

    let data = new FormData();
    data.append('user_activity_id', user_activity_id);
    data.append('media_id', String(id));

    
    let result = await removeActivityProof(data);

    if (result.response.status) {
      if( getData ) getData();
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setProofId(proof.id)
      sendProofLink(proof.id!)
    }
  };

  
  return (
    <>
       <div className="mb-5" style={{ cursor: 'pointer' , position: 'relative' }}>
                        {
                          moment(daySelected).isAfter(today) || hasHistory
                          
                          ?
                      
                        <div
                          style={{ 
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top:'0',
                            left:'0',
                            zIndex: '2',
                        }}
                        onClick={ () => {
                          if( moment(daySelected).isAfter(today) || hasHistory ){
                            toast.error("Can't open today");
                          }
                        } }
                        >
                        </div>
                        :
                        ''
                        }
                        <div className='d-flex justify-content-between align-items-center'>
                          <h5>{proof.name}</h5>
                        </div>

                        <div className="proof">
                          { proof.is_answered == 0 && (
                            <div>
                              {/*choose type*/}
                              <div className="mt-3 mb-3">
                                <label className="mb-2">Select Proof Type</label>
                                <div key={`inline-radio}`} className="mb-3">
                                <Form.Check
                                  checked={proofType == 'link'}
                                  inline
                                  label="Link"
                                  name={`group-${index}`}
                                  type='radio'
                                  id={`inline-${proof.id}-1`}
                                  onClick={() => {
                                    console.log('1')
                                    setProofId(proof.id)
                                    setProofType('link')
                                  }}
                                />
                                <Form.Check
                                checked={proofType == 'file'}
                                  inline
                                  label="File"
                                  name={`group-${index}`}
                                  type='radio'
                                  id={`inline-${proof.id}-2`}
                                  onClick={() => {
                                    console.log('2')
                                    setProofId(proof.id)
                                    setProofType('file')
                                  }}
                                />
                                
                              </div>
                                 
                              </div>
                              {/*choose type*/}
                              { (proofType == 'link') &&
                                <div className='d-flex align-items-center'>
                                  <div className="" style={{ flexGrow: '1' }} >
                                    <input
                                      type="text"
                                      className={`form-control m-0`}
                                      required
                                      onChange={e => setLinkName(e.target.value)}
                                      onKeyDown={handleKeyDown}
                                    />
                                  </div>
                                  <div className="">
                                    <button
                                    onClick={() => {
                                      setProofId(proof.id)
                                      sendProofLink(proof.id!)
                                    }}
                                    className={`${classes.SubmitBtn}`}>submit</button>
                                  </div>
                                </div>
                              }
                              { ( proofType == 'file') &&  
                              <div
                                className={`${practicalTaskClasses.UploadBtnWrapper} w-100 mb-4 mt-3`}
                              
                                >
                                <button className="w-100 p-4">
                                
                                    <img src="/practical-task/clip.png" />
                                  <div
                                    style={{
                                      color: '#FA5456',
                                      marginTop: '1rem',
                                      fontWeight: 'normal',
                                    }}>
                                    <strong style={{textDecoration: 'underline'}}>
                                      {t('Click')}
                                    </strong>{' '}
                                    {t('to attach your file')}
                                  </div>
                                </button>
                                <input
                                  type="file"
                                  name="myfile"
                                  className={practicalTaskClasses.UploadBtnWrapper}
                                  onClick={() => {
                                    setProofId(proof.id)

                                  }}
                                  onChange={(e) => { 
                                    
                                    handleFileChange(e)
                                    }}
                                  id="pictureX"
                                  disabled={moment(daySelected).isAfter(today)}
                                />
                              </div>
                              }
                              { (showProgressVal) && (<ProgressBar now={progressVal} label={`${progressVal}%`} />)}
                            </div>
                          )}
                        
                          {(  proof.is_answered && ((!moment(daySelected).isAfter(today) || moment(daySelected).isSame(today))) ) ? (
                            <div
                              className={`d-flex justify-content-between align-items-center`}>
                                <div className='d-flex align-items-center'>
                                <FaPaperclip
                                className="activity-icon"
                                  style={{
                                    color:colors.chamThirdColor ,
                                    background:colors.lightred,
                                    fontSize:'50px',
                                    borderRadius: '10px',
                                    padding: '12px',
                                  
                                  }}
                                  />

                                  <div className="d-flex flex-wrap">
                                    <div>{t('Proof Added')}</div>  
                                    {
                                    proof.is_answered && proof.prof_type != 'link' ? 
                                      <div
                                        onClick={() => {
                                          if (!moment(daySelected).isAfter(today)) {
                                            window.open(proof.prof, '_blank');
                                          } else {
                                            toast.error("Can't open today");
                                          }
                                        }}>
                                        <img
                                          src="/icons/open-eye.png"
                                          alt="image"
                                          style={{
                                            margin:'0 10px',
                                            cursor:'pointer'
                                          }}
                                        />
                                      </div>
                                      : ''
                                    }
                                    { proof.submitted_at && <div 
                                    style={ { flexBasis:'100%' } }
                                    className="secondry-color font-weight-bold submitted-at">Submitted At: {proof.submitted_at} </div>}
                                  </div>
                                  
                                </div>

                        {
                          proof.prof_marked == 0 &&
                          proof.prof_remarkable == 1 ? (
                            <span
                              style={{
                                border: '1px solid #FA5456',
                                padding: '5px',
                                borderRadius: '10px',
                                color:colors.chamThirdColor,
                                fontWeight: '500',
                              }}>
                              {t('waiting for marking')}
                            </span>
                          ) : (
                            ''
                          )}

                            

                        

                          <div className='d-flex align-items-center'>  

                            {
                          proof.prof_marked != 1 ? (
                              <div
                                onClick={() => {
                                  
                                  removeProf(proof.id)
                                  setprofMidea(null);
                                  setSelectedFile(null);
                                }}>
                                <img
                                  alt="img"
                                  src="/practical-task/close.png"
                                  style={{width: '20px'}}
                                  
                                />
                              </div>

                              ) : (
                                ''
                              )}
                                
                          {proof.prof_marked == 1 ? (
                                <FaRegCheckSquare
                                  size={19}
                                  color="green"
                                  style={{
                                    verticalAlign: 'text-bottom',
                                    margin: '1.5rem 0',
                                  }}
                                />
                              ) : (
                                ''
                              )}

                            {proof.percentage == 0 && ( <ZeroProgressPopover /> ) }

                              </div>

                            </div>

                          )
                        :
                        ''
                        }

                        
                        </div>
                        

                      </div>
    </>
  );
};

export default ProofItem;
