import moment from 'moment';
import classes from '../style/BookedSession.module.css';
import MainButton from '../../../UI/main-button/MainButton';
import {colors} from '../../../constants/colors';
import {Modal} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {AttendConsSess, JoinRoom, SendMail} from '../Consultants.service';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../../hooks/hooks';
import {setLoading} from '../../../store/userSlice';
import {NavLink} from 'react-router-dom';

const BookedSession = ({data, tab, toast}: any) => {
  const userId = localStorage.getItem('id');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [startModal, setStartModal] = useState(false);

  const comparedays = (date: string) => {
    let startTime = date.slice(
      0,
      date.indexOf('.') > 0 ? date.indexOf('.') : date.length,
    );
    let now = moment(new Date());
    let x = now.diff(startTime, 'minutes');
    let StartSession = x + 10;

    if (StartSession >= 0) return true;
  };

  const sendMail = async (id: number) => {
    setStartModal(false);
    dispatch(setLoading(true));
    let data = new FormData();

    try {
      data.append('consultation_session_id', String(id));
      let result = await SendMail(data);
      if (result.response.status) {
        dispatch(setLoading(false));
        toast('Mail Sended');
      } else {
        dispatch(setLoading(false));
        toast('Failed');
      }
    } catch (e) {
      dispatch(setLoading(false));
      toast('failed');
      console.log({e});
    }
  };

  const endConsSession = async (id: number) => {
    let result = await AttendConsSess(id);
    if (result.response) {
    }
  };
  return (
    <div className={`${classes.Item} d-flex row`}>
      <h5 className="font-weight-bold pl-5" style={{padding: '1rem 2rem 0'}}>
        {data?.is_course == 1 ? data?.lesson_title : data?.username}
      </h5>
      <span className={`${classes.Expired}`}>{data?.trans_status}</span>
      <div className={`${classes.SessionImage} col-md-3`}>
        <img
          src={
            data?.user_avatar
              ? data?.user_avatar
              : data?.is_course == 1
              ? data?.lesson_course_image
              : '/logo.png'
          }
          alt="course-img"
          style={{
            width: '130px',
            //padding: '2rem',
            margin: '0 0 0 2rem',
            display: 'block',
            height: '130px',
          }}
        />
      </div>
      <div className={`${classes.Details} col-md-9 d-flex flex-column`}>
        
        <div className="d-flex justify-content-between">
          <h4>
            {data?.is_course == 1
              ? data?.lesson_course_name
              : 'Consultant Request'}
          </h4>
          <div
            onClick={() => {
              navigate(`/chat/${userId}/${data?.user_id}`, {
                state: {
                  current: userId,
                  guest: data?.user_id,
                  senderName: localStorage.getItem('name'),
                  recName: data?.username,
                  senderAvatar: localStorage.getItem('avatar'),
                  recAvatar: data?.user_avatar,
                  senderFCM: 'await getToken()',
                  recFCM: data?.consultant_firebase_tokens,
                },
              });
            }}>

            {data?.is_course != "1" ? 
            <img
              src={'/icons/chat2.png'}
              alt="course-img"
              style={{
                width: '60px',
                background: '#f1fffe',
                padding: '10px',
                borderRadius: '10px',
              }}
            />
            :
            ''
            }
          </div>
        </div>
       
        <div className={`${classes.SessionDate} d-flex`}>
          <img
            src={'/icons/calendar2.png'}
            alt="course-img"
            style={{
              width: '20px',
              marginRight: '10px',
            }}
          />
          <div style={{fontWeight: '600'}} className="secondry-color mr-1 ml-1">
            {`${moment(data?.session_start_date_time).format(
              'dddd',
            )} - ${moment(data?.session_start_date_time).format(
              'DD MMM hh:mm A',
            )}`}
          </div>
        </div>
        {tab == 'upcoming' && (
          <MainButton
            text={'start now'}
            className="mt-3"
            onClick={async () => {
              if (comparedays(data.session_start_date_time)) {
                // setStartModal(true);
                endConsSession(data?.id);
                let name = localStorage.getItem('name') ?? '';
                let image =
                  localStorage.getItem('avatar') == 'null' ||
                  !localStorage.getItem('avatar')
                    ? 'http://example.com'
                    : localStorage.getItem('avatar');
                setStartModal(false);
                if( data.has_private_link != 0 ){
                  window.location.href = data.consultant_meeting_link
                }else{
                  let meet = await JoinRoom(
                    name.toString(),
                    'Host1',
                    data.meeting_id,
                    image!,
                    data.consultant_id,
                  );
                  if (meet?.data?.token) {
                    navigate(`/meet/${meet.data.token}`);
                  }
                }
              } else {
                new Date().getTime() >
                new Date(data?.session_end_date_time).getTime() - 5 * 60 * 1000
                  ? toast('meet Expired')
                  : toast('meet not start', 'error');
              }
            }}
            style={{
              backgroundColor: comparedays(data.session_start_date_time)
                ? colors.chamThirdColor
                : colors.regularGray,
              padding: 6,
              borderRadius: 13,
              borderWidth: 1,
              borderColor: comparedays(data.session_start_date_time)
                ? colors.chamThirdColor
                : colors.regularGray,
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
            }}
          />
        )}
      </div>
      <Modal show={startModal} onHide={() => setStartModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <img src="/logo.png" style={{maxWidth: '60px'}} />
            <h5 className="w-100 mt-4">
              {/* Now you will delete account ,, and lose all data */}
            </h5>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <MainButton
            text={'Start Session'}
            onClick={async () => {
              let name = localStorage.getItem('name') ?? '';
              let image =
                localStorage.getItem('avatar') == 'null' ||
                !localStorage.getItem('avatar')
                  ? 'http://example.com'
                  : localStorage.getItem('avatar');
              setStartModal(false);
              if( data.has_private_link != 0 ){
                window.location.href = data.consultant_meeting_link
              }else{
                let meet = await JoinRoom(
                  name.toString(),
                  'Host1',
                  data.meeting_id,
                  image!,
                  data.consultant_id,
                );
                if (meet?.data?.token) {
                  navigate(`/meet/${meet.data.token}`);
                }
              }
            }}
          />

          <MainButton
            text={'Share'}
            onClick={() => {
              const tempInput = document.createElement('input');
              tempInput.value = data?.url;
              document.body.appendChild(tempInput);

              // Select and copy the value
              tempInput.select();
              document.execCommand('copy');
              setStartModal(false);
              toast('copied', 'success');
              // Remove the temporary input
              document.body.removeChild(tempInput);
            }}
          />
          <MainButton
            text={'Send Link To My Email'}
            onClick={() => sendMail(data.id)}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookedSession;
