import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import {TrackingItemProps} from '../interfaces/ITrackings';
import classes from '../style/TrackingCard.module.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUserId} from '../../../auth/utiles';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MdOutlineKeyboardArrowRight, MdPadding } from 'react-icons/md';
const TrackingCard = ({
  showError,
  cumulative_score_calculated,
  hasPricedLevel,
  packagePrice,
  tracking,
  isPackage,
  packageId,
  enrolled,
  is_discover,
  current_user_id,
  showAssignUsersFamily
}: TrackingItemProps) => {

  const {t} = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();

  const userId = getUserId() ?? '';

  const queryParams = new URLSearchParams(location.search);

  const [discover, setDiscover] = useState<string>('0');

  const tabFromQueryParam = queryParams.get('tab');

  const packagePriceQueryParam = queryParams.get('package_price') || null ;
  const packageUlimitedPriceQueryParam = queryParams.get('package_unlimited_price') || null;
  const modelType = queryParams.get('type') || null;
  const model_id = queryParams.get('model_id') || null;
  const invitation_id = queryParams.get('invitation_id') || null;
  const inviteeId = queryParams.get('invitee_id') || null;


  useEffect(() => {
   
    
    if (tabFromQueryParam && tabFromQueryParam == 'discoverTrackingSystems' ) {
      setDiscover('1')
    }
  }, [location.search]);

  function handleClick(){
    if( isPackage ){
      if( tracking.type != 'tracking' && (tabFromQueryParam == null || tabFromQueryParam == 'userTackingSystems' ) ){
        window.location.href = `/user/initiatives/${tracking.id}/package/levels?discover=${discover}&current_user_id=${tracking.selected_enrolled_user_id}`
      }else{
        if( tracking.type == 'tracking' ){
          window.location.href = `/user/trackings/${tracking.id}/package/levels?discover=${discover}&package_price=${tracking.price}&package_unlimited_price=${tracking.unlimited_price}`
        }else{
          window.location.href = `/user/initiatives/${tracking.id}/package/levels?discover=1`
        }
      }
    }else{
      
      if( (tracking.tracking_type && tracking.tracking_type == 'single') || tracking.level ){

        if( tracking.type != 'tracking' ){

          
        
          if(  (tracking.selected_enrolled_user_id || current_user_id) && ( is_discover == '0' || is_discover == 0 || is_discover == undefined ) ){
            
            if( tracking.level && tracking.enrolled_in_level != 1   ){
              if( cumulative_score_calculated ){
                if(showError){ showError() }
              }else{
                window.location.href = `/user/trackings/${tracking.id}/details?is_level=${ tracking.level ? '1' : '0' }${ model_id ? `&model_id=${invitation_id}` : '' }${ invitation_id ? `&invitation_id=${invitation_id}` : '' }`
              }
              
            }else{
              
              let member_selected_id = tracking.selected_enrolled_user_id ?? current_user_id 
              window.location.href = `/user/initiative/${tracking.id}/details/enrolled/${member_selected_id}?trackType=initiative${ tracking.level ? `&packageId=${packageId}` : '' }`
            }
            
          }else{
            
           window.location.href = `/user/trackings/${tracking.id}/details?is_level=${ tracking.level ? '1' : '0' }${ modelType ? `&modal_type=${modelType}` : '' }${ model_id ? `&model_id=${invitation_id}` : '' }${ invitation_id ? `&invitation_id=${invitation_id}` : '' }${ inviteeId ? `&invitee_id=${inviteeId}` : '' }&has_priced_level=${hasPricedLevel}`
            
          }

        }else{
          
          if( tracking.selected_enrolled_user_id && ( is_discover != 1 ||  is_discover != '1' ) ){
            if( tracking.type == 'tracking' ){
              let url = `/user/trackings/${tracking.id}/details/enrolled/${tracking.selected_enrolled_user_id}?image=${tracking.image}`
              if( tracking.level ){
                url+=`&package_price=${packagePriceQueryParam}&package_unlimited_price=${packageUlimitedPriceQueryParam}`
              }
              navigate(url,
            
              {
                state: {
                  item: tracking,
                },
              });
            }else{
              window.location.href = `/user/initiatives/${tracking.id}/details/enrolled/${tracking.selected_enrolled_user_id}`
            }
          
          }else{
            window.location.href = `/user/trackings/${tracking.id}/details?is_level=${ tracking.level ? '1' : '0' }`
          }
        }

      }
      else if( tracking.tracking_enrollment_history_id ){
        let url = `/user/trackings/${tracking.id}/details/enrolled/${tracking.selected_enrolled_user_id}?image=${tracking.image}`
        if( tracking.tracking_enrollment_history_id ){
          url += `&tracking_enrollment_history_id=${tracking.tracking_enrollment_history_id}`
        }
      window.location.href = url
      }
    }
  }


  return (
    <div>
      <Card className="list-item-card  position-relative mb-3 cursor-pointer"
      
      >
      <p className='top-card-label'
      onClick={handleClick}
      >
        { !tracking.level ?
        
        <>
        {
        ( !tracking.level && tracking.type == 'tracking')
        ||
        ( !tracking.level && tracking.type == 'initiative' && tabFromQueryParam != 'discoverTrackingSystems') 
        ?
        <>
        {/*
        <span className='price-label'>
        { tracking.has_unlimited_price ? t('start from ') : '' }{tracking.price ? ((tracking.price + ' ' + tracking.user_currency) ?? ' USD') : t('Free') }
        </span>
        */}
        </>
        :

        ''
        }
        
        </>
        :
        ''
        }

        
        { tracking.type == 'initiative' && !tracking.level && tabFromQueryParam == 'discoverTrackingSystems' ?
        <>
        {/*
        <span className='mx-1 price-label'>
        {tracking.price ? ((tracking.price + ' ' + tracking.user_currency) ?? ' USD') : t('Free') }
        </span>
        */}
        </>
        :
          ''
        
        }

        

        

        {
          tracking.type == 'initiative' && tracking.enrolled_in_level == 0 ? 
          <img
          src={'/icons/lock.png'}
          alt="course-img"
        /> 
          :
          ''

        }
        

      </p>
      <Card.Img onClick={handleClick} variant="top" src={ tracking.image ? tracking.image : '/initiative/initiative.jpg' } />
      <Card.Body  className="mt-3">
        <div onClick={handleClick}>
        <Card.Title className="card-custom-title d-flex justify-content-between align-items-center">
        <span>{tracking.name.length > 25 ? tracking.name.slice(0,25) + ' ...' : tracking.name }</span>
        { tracking.level ?
        <span className='challenge-cart-label'>
          Level {tracking.level}
        </span>
        : '' }
        </Card.Title>
        <Card.Text className={`mt-3 card-custom-provider d-flex ${!isPackage && tracking.type != 'tracking' ? 'justify-content-between' : '' }`}>
          <div>
          <img src={ tracking.provider_image ?? `/champ-logo.png`} alt='logo' style={{ borderRadius:'50%' , width:'25px' , marginRight: '5px' }} />
            <span className='main-color'>{tracking.provider_name}</span>
          </div>

          
          {
            !isPackage && tracking.type != 'tracking' ?
            <div className='mb-md-3'> 
              <div className='d-flex align-items-center'>
                <img src="/calendar_line.svg" alt='img' />
                
                <p className='card-custom-date'>{moment(tracking.start_date).format('DD MMM, YYYY')} - {moment(tracking.end_date).format('DD MMM, YYYY')}</p>
              </div>
            </div>
          : '' }
          

          { isPackage ? 
          <span className='package-levels d-flex align-items-center'>
            <img src='/levels.svg' /> <span>{tracking.level_count + ' Levels'}</span>
          </span>
          : '' }

        </Card.Text>

        <Card.Text>
        {!enrolled && tracking?.has_unlimited_price == 1 && (
              <div style={{fontWeight: '600'}} className="text-dark">
                <div className='mt-2'>
                  <img
                    src={'/icons/coin.png'}
                    alt="course-img"
                    style={{
                      margin: '0 10px',
                      width: '20px',
                      verticalAlign: 'sub',
                    }}
                  />

                  <span>limited</span>
                  <span> {tracking?.price} {tracking?.user_currency}</span>

                </div>

                <div className='mt-2'>
                  <img
                    src={'/icons/coin.png'}
                    alt="course-img"
                    style={{
                      margin: '0 10px',
                      width: '20px',
                      verticalAlign: 'sub',
                    }}
                  />

                  <span>unlimited</span>
                  <span> {tracking?.unlimited_price} {tracking?.user_currency}</span>
                  
                </div>
                
              </div>
              
            )}
          
            
           

            {
            
              tracking.enrolled_members
              
              ?
                
              <div className="d-flex text-center justify-content-center">
                {
                tracking.enrolled_members.length > 0
              
                  ? tracking.enrolled_members.map((item, index) => (
                      <div key={index} style={{margin: '0 10px'}}>
                        <img
                          src={item.avatar ?? '/icons/manHolder.png'}
                          style={{
                            width: '20px',
                            height: '25px',
                            margin: 'auto',
                            borderRadius: '50%',
                          }}
                        />
                        <h6>
                          {item.username.length > 7
                            ? item.username.substring(0, 7) + ' ...'
                            : item.username}
                        </h6>
                      </div>
                    ))
                  : ''}
              </div>

              : 
              
              ''
            
            }
        </Card.Text>
        </div>

        {
          (tracking.level && (tracking.price || (tracking.price == 0 && packagePrice) ) && !tracking.enrolled_in_level && hasPricedLevel ) ? 
          <div 
          onClick={ () => { 

            if(showAssignUsersFamily){ showAssignUsersFamily(tracking.id)}
           }} 
          
          className='d-flex justify-content-center align-items-center cursor-pointer book-level-btn'>
            <p className='mb-0' style={{color: '#FA5456' , fontWeight: '500' , paddingRight: '10px' }}>Subscribe To This Level</p>
            {/*<MdOutlineKeyboardArrowRight style={{ margin: '0 5px' }} size="20" color="#FA5456" />*/}
            { tracking.type == 'initiative' && tracking.level ?
            <p className='mx-2 mb-0' style={{color: '#FA5456' , fontWeight: '500' , padding: '0 15px' , borderLeft: '2px solid' }}>
            {tracking.price ? ((tracking.price + ' ' + tracking.user_currency) ?? ' USD') : t('Free') }
            </p>
            :
              ''
            }
            
          </div>
          : '' 
        }

        {/*
        (tracking.level && (tracking.price || (tracking.price == 0 && packagePrice) ) && tracking.enrolled_in_level && hasPricedLevel )
        */}
        {
          (tracking.level && tracking.enrolled_in_level ) ? 
          <div className='d-flex justify-content-center align-items-center cursor-pointer booked-level-btn'>
             <img src={'/right.svg'} alt="track-img" /> 
            <p className='mb-0' style={{color: '#4ACD7F' , fontWeight: '500' , paddingLeft: '10px' }}>Subscribed in this level</p>
          </div>
          : '' 
        }

        { tabFromQueryParam == 'discoverTrackingSystems' ?
        <div onClick={handleClick}>
          {/*
            <div onClick={handleClick}>
                
            <Button className="card-custom-button">
                    Join { tracking.type != 'tracking' ? t('Challenge') : t('Follow-up System') }
            </Button>
          </div>
          */}

          <div onClick={handleClick} style={{ color : '#FF5659' , fontWeight: '500' }} className='text-center'>
            {/*
            <Button className="card-custom-button">
                    {t('Join Challenge')}
            </Button>
            */}
            Challenge Details <MdOutlineKeyboardArrowRight size="25" color="#FF5659" />
          </div>
        </div>
          : ''}
        
       
      </Card.Body>
    </Card>

    </div>
  );
};

export default TrackingCard;