import {Col, Container, Pagination, Row} from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ConsultantSessionCard from './components/ConsultantSessionCard';
import './style/UserSessions.css';
import {useEffect, useState} from 'react';
import {getSessionsUser} from './Sessions.service';
import {Sessions} from './interface/ISessions';
import ItemsPagination from '../../UI/pagination/ItemsPagination';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import toast, {Toaster} from 'react-hot-toast';

function UserSessions() {
  const dispatch = useAppDispatch();
  const Loading = useAppSelector(state => state.user.loading);
  const [sessions, setSessions] = useState<Sessions[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [tab, setTab] = useState<string | null>('upcomming');

  useEffect(() => {
    
    getSessions('upcomming', 1);
  }, []);

  const getSessions = async (key: string | null, page: number) => {
    setSessions([]);
    dispatch(setLoading(true));
    let data = new FormData();
    data.append('is_consultant', '0');
    data.append('is_completed', key == 'upcomming' ? '0' : '1');

    let result = await getSessionsUser(data, page);

    if (result.response) {
      setSessions([...result.response.data.data]);
      setCurrentPage(result.response.data.current_page);
      setLastPage(result.response.data.last_page);
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
    }
  };
  return (
    <div className='position-relative'>
     

      {/* User Courses Tabs */}
      <Tabs
        defaultActiveKey="upcomming"
        id="uncontrolled-tab-example"
        onSelect={(key: string | null) => {
          setTab(key);
          getSessions(key, 1);
        }}
        className="custom-web-tabs mb-5">
        <Tab eventKey="upcomming" title="Upcoming Sessions">
          <Row>
            {Loading == false && sessions.length == 0 && (
              <div className="text-center">
                <img src="/logo.png" alt="logo" width={80} />
                <h4 className="mt-4 mb-2">Sorry, there is no data found</h4>
              </div>
            )}
            {sessions.map(i => {
              return (
                <Col lg={6}>
                  <ConsultantSessionCard
                    session={i}
                    upcomming={true}
                    toast={(msg, type) => {
                      if (type == 'error') {
                        toast.error(msg);
                      } else {
                        toast.success(msg);
                      }
                    }}
                    refreshList={() => getSessions?.('upcomming', 1)}
                  />
                </Col>
              );
            })}
          </Row>
        </Tab>
        <Tab eventKey="previous" title="Previous Sessions">
          <Row>
            {Loading == false && sessions.length == 0 && (
              <div className="text-center">
                <img src="/logo.png" alt="logo" width={80} />
                <h4 className="mt-4 mb-2">Sorry, there is no data found ...</h4>
              </div>
            )}
            {sessions.map(i => {
              return (
                <Col lg={6} className="mb-4">
                  <ConsultantSessionCard
                    session={i}
                    upcomming={false}
                    toast={(msg, type) => {
                      if (type == 'error') {
                        toast.error(msg);
                      } else {
                        toast.success(msg);
                      }
                    }}
                    refreshList={() => getSessions?.('previous', 0)}
                  />
                </Col>
              );
            })}
          </Row>
        </Tab>
      </Tabs>
      {lastPage != 1 && sessions.length != 0 && (
        <div className="pagination-container">
          <ItemsPagination
            pages={lastPage}
            current={currentPage}
            changePage={(page: number) => {
              setCurrentPage(page);
              getSessions(tab, page);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default UserSessions;
