import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {APIURL} from '../services/http/api';
import {HttpService} from '../services/http/http.service';
import {HandleAPIParams} from '../helpers/HandleAPIParams';
import {ConsultantsType} from '../Screens/Consultants/interfaces/IConsultants';
import {setLoading} from './userSlice';

export type data = {
  data: {[key: string]: any};
  load: boolean | null;
};

export interface ConsultantState {
  allConsultants: ConsultantsType[];
  page: number;
  lastPage: number;
  loading: boolean;
}

export const insertConsultantsList = createAsyncThunk(
  'consultants/getConsultants',
  async ({data}: data, {dispatch}) => {
    const http = new HttpService();
    const api = APIURL.experts.getExperts;
    let finalApi = HandleAPIParams(api + '?', data);
    try {
      const [err, resp] = await http.get(finalApi);
      dispatch(setLoading(false));
      console.log('resp' , resp )
      document.getElementById('filterContainer')?.classList.remove('hidden-filter'); 
      return resp;
    } catch (error) {}
  },
);

const ConsultantsSlice = createSlice({
  name: 'consultants',
  initialState: {
    allConsultants: [],
    page: 1,
    lastPage: 1,
    loading: false,
  } as ConsultantState,
  reducers: {},
  extraReducers: {
    [`${insertConsultantsList.pending}`]: (
      state: ConsultantState,
      action: PayloadAction<any>,
    ) => {
      state.loading = true;
    },
    [`${insertConsultantsList.fulfilled}`]: (
      state: ConsultantState,
      action: PayloadAction<any>,
    ) => {
      state.allConsultants = action.payload.data.data.data;
      state.page = action.payload.data.data.current_page;
      state.lastPage = action.payload.data.data.last_page;
      state.loading = false;
    },
    [`${insertConsultantsList.rejected}`]: (
      state: ConsultantState,
      action: PayloadAction<any>,
    ) => {
      state.loading = false;
    },
  },
});

export default ConsultantsSlice.reducer;
