import Card from 'react-bootstrap/Card';
import './Story.css'


function Story(props) { 
  
  return (
    <Card className="story-card text-center" >
      
      <div style={ { 'position' : 'relative' } }>
        <Card.Img variant="top" src="/initiative/initiative.jpg" />
        <img src='https://www.pngitem.com/pimgs/m/404-4042710_circle-profile-picture-png-transparent-png.png' className='story-user' alt="img" />
      </div>
      <Card.Body className='mt-4 mb-2'>
        <Card.Title className="story-card-title" >Ahmed Taha</Card.Title>
      </Card.Body>
    </Card>
  );
}

export default Story;
