import {Fragment} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {isUserLoggedIn} from '../../auth/utiles';
import { getDomain } from '../../helpers/getDomain';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const Achievements = () => {

  const {t} = useTranslation();

  const navigate = useNavigate();
 
  const Logged = isUserLoggedIn();

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const [items, setItems] = useState(null);

  const getData = async () => {
    
    let token = localStorage.getItem('token');
    
    let url = `/api/mobile/getUserAchievements`
   
    try {
      let result = await axios.get(getDomain() + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('bannar', result.data);
      setItems(result.data.data)  
      
        
    } catch (e) {
      
      console.log(e);
    }
  };

  useEffect(() => {
    if( Logged ){
      getData()
    }
  }, []);

  

  return (
    <Fragment>
      <div className="mt-5 pb-5">
        <Container>
          <Row className='justify-content-center'>

            <Col md="12" className='mb-md-3' >
              <h1
                className="mb-4 text-center"
                style={{fontWeight: 'bold', color: '#7b7b7b'}}>
                {t('My Achievements')}
              </h1>
               
            </Col>
            
           
              
               
           

          </Row>
        </Container>
        <div className='d-flex achievement-container hideScroll'>
        <div className='achievement-card challanges'
         onClick={() => {  navigate('/user/initiatives/') }}
         >
                <div className='d-flex justify-content-between align-items-center mb-4'
               
                >
                  <div className='ach-number'>
                  { items?.challenges ?? 0 }
                  </div>
                  <div>
                    <img src="/ach1.svg" />
                  </div>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                  <h4>{t('Challenges')}</h4>
                  <div>
                    <img src="/custom-arrow.svg"  className='go' />
                  </div>
                </div>
              </div>
               
            
           
              <div className='achievement-card follow-up'
              onClick={() => {  navigate('/user/trackings/list/?tab=userTackingSystems') }}
              >
                <div className='d-flex justify-content-between align-items-center mb-4'
                
                >
                  <div className='ach-number'>
                  { items?.followUps ?? 0 }
                  </div>
                  <div>
                    <img src="/ach2.svg" />
                  </div>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                  <h4>{t('Follow Up')}</h4>
                  <div>
                    <img src="/custom-arrow.svg"  className='go' />
                  </div>
                </div>
              </div>
               
           

            
              <div className='achievement-card courses'
              onClick={() => {  navigate('/show/user/profile?tab=courses') }}
              >
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <div className='ach-number'>
                  { items?.courses ?? 0 }
                  </div>
                  <div>
                    <img src="/ach3.svg" />
                  </div>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                  <h4>{t('Courses')}</h4>
                  <div>
                    <img src="/custom-arrow.svg"  className='go' />
                  </div>
                </div>
              </div>
               
           

            
              <div className='achievement-card sessions'
              onClick={() => {  navigate('/show/user/profile?tab=sessions') }}
              >
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <div className='ach-number'>
                  { items?.sessions ?? 0 }
                  </div>
                  <div>
                    <img src="/ach4.svg" />
                  </div>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                  <h4>{t('Sessions')}</h4>
                  <div>
                    <img src="/custom-arrow.svg"  className='go' />
                  </div>
                </div>
              </div>
               
           
              <div className='achievement-card invitation'>
                <div className='d-flex justify-content-between align-items-center mb-4'>
                  <div className='ach-number'>
                  { items?.referal_invitations ?? 0 }
                  </div>
                  <div>
                    <img src="/ach5.svg" />
                  </div>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                  <h4>{t('Invitations')}</h4>
                  <div>
                    <img src="/custom-arrow.svg"  className='go' />
                  </div>
                </div>
              </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Achievements;
