export const getChatDomain = () => {
  
    let chatDomainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'championsdev.championsacademy.ca' ){
        chatDomainName = 'DEV'
    }

    else if( hostName == 'championstest.championsacademy.ca' ){
        chatDomainName = 'TEST'
    }

    else if( hostName == 'championsprod.championsacademy.ca' || hostName == 'championsacademy.ca' ){
        chatDomainName = 'Chats'
    }
    
    else{
        chatDomainName = 'DEV'
    }
    
    
    return chatDomainName
  
};
