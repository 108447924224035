export const getDomain = () => {
  
    let domainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'championsdev.championsacademy.ca' ){
        domainName = 'https://dev.championsacademy.ca'
        //domainName = 'https://nurabi.live'
        
    }

    else if( hostName == 'championstest.championsacademy.ca' ){
        domainName = 'https://test.championsacademy.ca'
    }

    else if(hostName == 'championsprod.championsacademy.ca' || hostName == 'championsacademy.ca'){
        domainName = 'https://main.championsacademy.ca'
    }
    
    else{
        domainName = 'https://dev.championsacademy.ca'
    }
    
    
    return domainName
  
};
