import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import {EventsItemProps} from '../interfaces/IEvents';
import classes from '../style/TrackingCard.module.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {getUserId} from '../../../auth/utiles';
import { FaLongArrowAltRight, FaRegCalendarAlt } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getUserCurrency } from '../../../helpers/getUserCurrency';
const EventCard = ({
  eventItem 
}: EventsItemProps) => {

  const {t} = useTranslation();
  

  const location = useLocation();

  const navigate = useNavigate();

  const userId = getUserId() ?? '';

  const queryParams = new URLSearchParams(location.search);

  const [discover, setDiscover] = useState<string>('0');

  const tabFromQueryParam = queryParams.get('tab');

  const packagePriceQueryParam = queryParams.get('package_price') || null ;
  const packageUlimitedPriceQueryParam = queryParams.get('package_unlimited_price') || null;
  const userEnrolled = queryParams.get('userEnrolled') || '0';



  useEffect(() => {
   
    
    if (tabFromQueryParam && tabFromQueryParam == 'discoverTrackingSystems' ) {
      setDiscover('1')
    }
  }, [location.search]);

  


  return (
    <div>
      <Card className="list-item-card  position-relative mb-3 cursor-pointer"
      
      >
      <p className='top-card-label'>
        
       
        { eventItem.paid != 2 ? 
        <span className='price-label'>
          {eventItem.paid ? eventItem.price + ` ${eventItem.user_currency}` : 'Free'  }
        </span>
        : '' }
      

        { tabFromQueryParam == 'events' ? 
        <span className='subscribed-label'>
          {eventItem.username}
        </span>
        : '' }
        

        
        

      </p>
      <Card.Img variant="top" src={ eventItem.image } alt='img' />
      <Card.Body className="mt-3">
        <div>
        <Card.Title className="card-custom-title">
        {eventItem.title}
        </Card.Title>
        <Card.Text className="card-custom-provider">{ eventItem.provider_name }</Card.Text>

        

       
        </div>

        
        
      
         
          
          
        
       
      </Card.Body>
    </Card>

    </div>
  );
};

export default EventCard;
