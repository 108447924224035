import Card from 'react-bootstrap/Card';
import consultantImg from '../style/consultant.jpg';
import RateStars from '../../../UI/rate-stars/RateStars';
import '../style/ConsultantCard.css';
import {useNavigate, useLocation} from 'react-router-dom';
import {ConsultantItemProps} from '../interfaces/IConsultants';
import classes from '../style/ConsultantCardHorizontal.module.css';
import {isUserLoggedIn} from '../../../auth/utiles';
import {useTranslation} from 'react-i18next';

function HomeConsultantCard({consultant}: ConsultantItemProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const Logged = isUserLoggedIn();
  const location = useLocation();

  function handleClick() {
    if (Logged) {
      navigate(
        `/consultants/${consultant?.consultation_info_type}/${consultant?.id}`,
      );
    } else {
      navigate(`/login`, {state: {location: location.pathname}});
    }
  }
  const fields = consultant?.fields_names?.split(', ').slice(0, 2);
  const languages_names = consultant?.languages_names?.split(', ');
  return (
    <Card className="mentor consultant-card position-relative" onClick={handleClick}>
      <p className='top-card-label'>
        {consultant?.first_session_free == 1 && (
        <span className='price-label'>
        {t('First Session Free')}
        </span>
        )}

        <span className='subscribed-label'>
        {consultant?.available_sessions} {t('available')}
        </span>

      </p>
      <Card.Img
        variant="top"
        src={consultant?.avatar ?? consultantImg}
        className="ConsAvatar"
      />
      <Card.Body className="mt-3 mb-1">
        <Card.Title className="card-custom-title card-title h5">
          {consultant?.username}
        </Card.Title>

        <Card.Title>
          {fields?.map((l, i) => (
            <span style={{fontSize: '17px'}} className="card-custom-specialize" key={i}>
              {l} {i + 1 < fields?.length ? ' . ' : ''}
            </span>
          ))}
        </Card.Title>

        <div className="d-flex mb-2 mt-3 align-items-center">
          <div
            className={`d-flex mb-1 align-items-center`}>
            {languages_names?.slice(0, 2).map((l, i) => (
              <span className='card-lang' key={i}>{l}, </span> 
            ))}

            {languages_names && languages_names?.length > 2 && (
              <p>+{languages_names?.length - 2}</p>
            )}
          </div>
        </div>

        {/*
        <Card.Text className="mb-2 consultant-rate">
          <RateStars rateValue={consultant?.ratings} />
        </Card.Text>
        */}
       
      </Card.Body>
    </Card>
  );
}

export default HomeConsultantCard;
