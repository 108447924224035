import {APIURL} from '../../services/http/api';
import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';

const http = new HttpService();

export const getTrackingsList = async (
  page: number | string,
  isMine: number | string,
) => {
  const api = APIURL.tracking.getAllTracking;
  const finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
    [EApiParams.isMine]: isMine,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getTrackingsLevels = async (id: number | string) => {
  const api = APIURL.tracking.getLevelsTrack;
  const finalApi = urlParamReplace(api, {
    [EApiParams.tracking_id]: id,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getTrackingsDetailsBeforeEnroll = async (id: number | string) => {
  const api = APIURL.tracking.showBeforeEnroll;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const enrollFree = async (data: FormData) => {
  const api = APIURL.tracking.enrollFree;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const addCart = async (data: any) => {
  const api = APIURL.cart.add;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getmemberForAssign = async (trackId: any) => {
  const api = APIURL.tracking.getMembersForAssign;
  const finalApi = urlParamReplace(api, {
    [EApiParams.tracking_id]: trackId,
  });

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const assignAndStartTrack = async (data: any) => {
  const api = APIURL.tracking.assignStartTrack;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getTracksMembers = async (id: any, type: any, page: any) => {
  const api = APIURL.tracking.getEnrolledUsers;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
    [EApiParams.type]: type,
    [EApiParams.page]: page,
  });

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getTrackingsDetailsAfterEnroll = async (
  id: string | undefined,
  date: string,
  currentUserId: null | string,
  historyId: number,
  user_owned_tracking_id: number,
) => {
  const api = urlParamReplace(APIURL.tracking.showAfterEnroll, {
    [EApiParams.id]: id,
    [EApiParams.date]: date,
  });

  let finalApi = '';
  if (historyId) {
    finalApi = `${api}&current_user_id=${currentUserId}&tracking_enrollment_history_id=${historyId}`;
  } else if (user_owned_tracking_id) {
    finalApi = `${api}&user_owned_tracking_id=${user_owned_tracking_id}`;
  } else {
    finalApi = `${api}&current_user_id=${currentUserId}`;
  }

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getActivityData = async (
  id: number | string | null,
  date: string,
  currentUserId: string,
  historyId: string | null,
  trackingId: string,
) => {
  const api = urlParamReplace(APIURL.tracking.getActivity, {
    [EApiParams.id]: id,
    [EApiParams.date]: date,
  });

  let finalApi = '';
  if (historyId && historyId != 'null') {
    finalApi = `${api}&current_user_id=${currentUserId}&tracking_enrollment_history_id=${historyId}`;
  } else {
    finalApi = `${api}&current_user_id=${currentUserId}&tracking_id=${trackingId}`;
  }
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const addTrackNote = async (data: any) => {
  const api = APIURL.tracking.addNote;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const editTrackNote = async (data: any) => {
  const api = APIURL.tracking.editNote;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const updateProgressAutomatic = async (data: any) => {
  const api = APIURL.tracking.updateProgressAutomatic;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const removeActivityProof = async (data: any) => {
  const api = APIURL.tracking.removeActivityProof;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};



export const updateProgress = async (data: any) => {
  const api = APIURL.tracking.updateProgress;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const sendInitiativeProf = async (data: any) => {
  const api = APIURL.initiative.sendProf;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const repeatTrack = async (trackId: any, data: any) => {
  const api = APIURL.tracking.repeatTrack;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: trackId,
  });

  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getAllLevels = async (
  id: number,
  page: number,
  isMine: number,
) => {
  const api = APIURL.tracking.getAllLevels;
  const finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
    [EApiParams.isMine]: isMine,
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};
export const getTracks = async (id: number, page: number, isMine: number) => {
  const api = APIURL.tracking.getTracks;
  const finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
    [EApiParams.isMine]: isMine,
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};
