import Card from 'react-bootstrap/Card';
import './InitiativeCard.css';
import {useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import MainButton from '../main-button/MainButton';
import { Button } from 'react-bootstrap';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import moment from 'moment';

function InitiativeCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleClick() {
    if( props.isPackage ){
      if( props.selected_enrolled_user_id ){
        window.location.href = `/user/initiatives/${props.id}/package/levels?discover=0`
      }else{
        window.location.href = `/user/initiatives/${props.id}/package/levels?discover=1`
      }
    }else{
      if( (props.tracking_type && props.tracking_type == 'single') || props.level ){

        if( props.selected_enrolled_user_id ){
          window.location.href = `/user/initiative/${props.id}/details/enrolled/${props.selected_enrolled_user_id}`
        }else{
          window.location.href = `/user/trackings/${props.id}/details?is_level=0`
          
        }
      }
    }
  }
  return (
    <Card className="initiative-card home-custom-card position-relative" onClick={handleClick}>
      <p className='top-card-label'>
        <span className='price-label d-none'>
        {props.price ? ((props.price + ' ' + props.user_currency) ?? ' USD') : t('Free') }
        </span>

        {/*
        <span className='subscribed-label'>
          Subscribed for other
        </span>
        */}

      </p>
      
      <Card.Img variant="top" src={ props.image ? props.image : '/initiative/initiative.jpg' } />
      <Card.Body className="card-custom-body">
        <Card.Title className="card-custom-title">
          {props.name.length > 25 ? props.name.slice(0,25) + ' ...' : props.name }
        </Card.Title>
        <Card.Text className="card-custom-provider d-flex">
          <img src='/champ-logo.png' alt='logo' style={{ borderRadius:'50%' , width:'25px' , marginRight: '5px' }} />
          <span>{props.provider_name}</span>
        </Card.Text>
        <Card.Text className="mb-2 initiative-rate">
          {!props.isPackage ? 
          <>
          <div className='d-flex align-items-center'>
            <img src="/calendar_line.svg" alt='img' />
            
            <p className='card-custom-date'>{moment(props.start_date).format('DD MMM, YYYY')} - {moment(props.end_date).format('DD MMM, YYYY')}</p>
          </div>
          
          
          </>
          :  
          <div className='d-flex align-items-center'>
            <p className='card-custom-date'>{props.level_count} levels</p>
          </div>
          }
          <div onClick={handleClick} style={{ color : '#80d8f7' , fontWeight: '500' }} className='text-center mt-3'>
            {/*
            <Button className="card-custom-button">
                    {t('Join Challenge')}
            </Button>
            */}
            More Details <MdOutlineKeyboardArrowRight size="25" color="#80d8f7" />
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default InitiativeCard;
