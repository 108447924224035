import {APIURL} from '../../services/http/api';
import {urlParamReplace} from '../../helpers/UrlParamReplace';
import {EApiParams} from '../../services/http/apiParams.enum';
import {HttpService} from '../../services/http/http.service';
import { HandleAPIParams } from '../../helpers/HandleAPIParams';

const http = new HttpService();

export const getTrackingsList = async (
  page: number | string,
  title:string,
  languages:number[]|null,
  followUpTypes:number[]|null,
  providerSelected:number[]|null,
  priceSelected:number[]|null,
) => {
  const api = APIURL.tracking.getMyFollowUpSystem;
  let finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
  });

  let data: {[key: string]: any} = {};

  data["title"] = title

  languages?.length &&
    languages.map((i, index) => {
      data[`language_id[${index}]`] = i;
    });

  followUpTypes?.length &&
  followUpTypes.map((i, index) => {
    data[`level_types[${index}]`] = i;
  });

  providerSelected?.length &&
  providerSelected.map((i, index) => {
    data[`providers[${index}]`] = i;
  });
  
  priceSelected?.length &&
  priceSelected.map((i, index) => {
    data[`price_types[${index}]`] = i;
  });  

  
  

  
  finalApi = HandleAPIParams(finalApi + '?', data);
  
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};


export const discoverTrackingsList = async (
  page: number | string,
  title:string,
  languages:number[]|null,
  followUpTypes:number[]|null,
  providerSelected:number[]|null,
  priceSelected:number[]|null,
) => {
  const api = APIURL.tracking.getDiscoverFollowUpSystem;
  let finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
  });

  let data: {[key: string]: any} = {};

  data["title"] = title

  languages?.length &&
    languages.map((i, index) => {
      data[`language_id[${index}]`] = i;
    });

  followUpTypes?.length &&
  followUpTypes.map((i, index) => {
    data[`level_types[${index}]`] = i;
  });

  providerSelected?.length &&
  providerSelected.map((i, index) => {
    data[`providers[${index}]`] = i;
  });
  
  priceSelected?.length &&
  priceSelected.map((i, index) => {
    data[`price_types[${index}]`] = i;
  });  

  
  

  
  finalApi = HandleAPIParams(finalApi + '?', data);
  
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};


export const getAllPackageLevels = async (id: number | string) => {
  const api = APIURL.tracking.getAllLevels;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
    [EApiParams.page]: 1
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};






export const getTrackingsDetailsBeforeEnroll = async (id: number | string) => {
  const api = APIURL.tracking.followUpSystemDetails;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};



export const enrollFree = async (data: FormData) => {
  const api = APIURL.tracking.enrollFree;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const addCart = async (data: any) => {
  const api = APIURL.cart.add;
  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};


export const getmemberForAssign = async (trackId: any) => {
  const api = APIURL.initiative.getMembersForMyInitiatives;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: trackId,
  });

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getmemberForAssignPackages = async (trackId: any) => {
  const api = APIURL.initiative.getMembersForMyInitiativesPackages;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: trackId,
  });

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getmemberForAssignPackagesLevels = async (trackId: any) => {
  const api = APIURL.initiative.getMembersForMyInitiativesPackagesLevels;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: trackId,
  });

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const assignAndStartTrack = async (data: any) => {
  const api = APIURL.tracking.assignStartTrack;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getTracksMembers = async (id: any, type: any, page: any) => {
  const api = APIURL.tracking.getEnrolledUsers;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: id,
    [EApiParams.type]: type,
    [EApiParams.page]: page,
  });

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getTrackingsDetailsAfterEnroll = async (
  id: string | undefined,
  date: string,
  currentUserId: null | string,
  historyId: number,
  user_owned_tracking_id: number,
) => {
  const api = urlParamReplace(APIURL.tracking.showAfterEnroll, {
    [EApiParams.id]: id,
    [EApiParams.date]: date,
  });

  let finalApi = '';
  if (historyId) {
    finalApi = `${api}&current_user_id=${currentUserId}&tracking_enrollment_history_id=${historyId}`;
  } else if (user_owned_tracking_id) {
    finalApi = `${api}&user_owned_tracking_id=${user_owned_tracking_id}`;
  } else {
    finalApi = `${api}&current_user_id=${currentUserId}`;
  }

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const getActivityData = async (
  id: number | string | null,
  date: string,
  currentUserId: string,
  trackingId: string,
) => {
  const api = urlParamReplace(APIURL.tracking.getActivity, {
    [EApiParams.id]: id,
    [EApiParams.date]: date,
  });

  let finalApi = '';
  finalApi = `${api}&current_user_id=${currentUserId}&tracking_id=${trackingId}`;
  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};

export const addTrackNote = async (data: any) => {
  const api = APIURL.tracking.addNote;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const editTrackNote = async (data: any) => {
  const api = APIURL.tracking.editNote;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const updateProgressAutomatic = async (data: any) => {
  const api = APIURL.tracking.updateProgressAutomatic;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const updateProgress = async (data: any) => {
  const api = APIURL.tracking.updateProgress;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const sendInitiativeProf = async (data: any) => {
  const api = APIURL.initiative.sendProf;

  const [err, resp] = await http.post(api, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const repeatTrack = async (trackId: any, data: any) => {
  const api = APIURL.tracking.repeatTrack;
  const finalApi = urlParamReplace(api, {
    [EApiParams.id]: trackId,
  });

  const [err, resp] = await http.post(finalApi, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return {error: err, response: resp?.data};
};

export const getAllLevels = async (
  id: number,
  page: number,
  isMine: number,
) => {
  const api = APIURL.tracking.getAllLevels;
  const finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
    [EApiParams.isMine]: isMine,
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};
export const getTracks = async (id: number, page: number, isMine: number) => {
  const api = APIURL.tracking.getTracks;
  const finalApi = urlParamReplace(api, {
    [EApiParams.page]: page,
    [EApiParams.isMine]: isMine,
    [EApiParams.id]: id,
  });
  const [err, resp] = await http.get(finalApi);

  return {error: err, response: resp?.data};
};


export const getTrackData = async (id:any, current_user_id:any) => {
  const api = urlParamReplace(APIURL.initiative.getInitiativeData, {
    [EApiParams.id]: id,
    [EApiParams.family_member_id]: current_user_id,
  });

  const [err, resp] = await http.get(api);

  return {error: err, response: resp?.data};
};

export const getDataAfterEnroll = async (
  id:any,
  date:any,
  currentUserId:any,
  historyId:any,
) => {
  const api = urlParamReplace(APIURL.tracking.showAfterEnroll, {
    [EApiParams.id]: id,
    [EApiParams.date]: date,
  });

  let finalApi = '';
  if (historyId) {
    finalApi = `${api}&current_user_id=${currentUserId}&tracking_enrollment_history_id=${historyId}`;
  } else finalApi = `${api}&current_user_id=${currentUserId}`;

  const [err, resp] = await http.get(finalApi);
  return {error: err, response: resp?.data};
};
