import {Fragment, useRef, useState} from 'react';

import {Col, Container, Row} from 'react-bootstrap';

import './Home.css';

import {useLocation, useNavigate} from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import {useEffect} from 'react';
import axios from 'axios';
import { getDomain } from '../../helpers/getDomain';
const Sliders = () => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    getSliders()
  }, []);

  const getSliders = async () => {
    
    let token = localStorage.getItem('token');
    
    let url = `/api/mobile/sliders-panners/get/sliders`
   
    try {
      let result = await axios.get(getDomain() + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('sliders', result.data.data);

      setItems( result.data.data)
    } catch (e) {
      
      console.log(e);
    }
  };


 
  return (
    <Fragment>
     <div className='home-banner'>
      { items?.length ? 
      <Carousel >
      {items?.map(i => (
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={i.image}
            alt="First slide"
          />
        
        </Carousel.Item>
        ))}
      </Carousel>
      : ''}
      </div>
     
    </Fragment>
  );
};

export default Sliders;
