import {Col, Container, Row} from 'react-bootstrap';
import './style/ConsultantProfile.css';
import ConsultantCard from './components/ConsultantCard';
import ConsultantTabs from './components/ConsultantTabs';
import {useNavigate, useParams} from 'react-router-dom';
import {getConsultantData, getSurvey} from './Consultants.service';
import {useEffect, useState} from 'react';
import {ConsultantParams} from './types';
import {ConsultantsType} from './interfaces/IConsultants';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import {Questionnaire} from './interfaces/IQuestionnaire';
import ConsultantSessionsBtnActions from './components/ConsultantSessionsBtnActions';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import ShareIcon from '../../components/share-icon/ShareIcon';

const Consultant = () => {
  const navigate = useNavigate();
  let {id}: ConsultantParams = useParams<ConsultantParams>();
  const [item, setItem] = useState<ConsultantsType | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [survey, setSurvey] = useState<Questionnaire | null | undefined>();
  const [tab, setTab] = useState<string>('');

  useEffect(() => {
    getData();
    getSurv();
  }, []);

  const getData = async () => {
    setLoading(true);
    let result = await getConsultantData(id);

    if (result.response.data) {
      setItem(result.response.data);
      // getCourses(coursesPage);
      // getTracking(trackingPage);
    }
    setLoading(false);
  };

  const getSurv = async () => {
    let result = await getSurvey(id);
    if (result.response) {
      setSurvey(result.response.data);
    }
  };

  useEffect(() => {
    createDynamicLink();
  }, []);

  const [url, setUrl] = useState('https://championsacademy.page.link');

  const createDynamicLink = async () => {
   
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://championsacademy.page.link',
        link: `${getDynamicLinkDomain()}/?type=Consultant&model_id=${id}`,
        androidInfo: {
          androidPackageName: 'com.championsacademy',
        },
        iosInfo: {
          iosBundleId: 'org.Champions.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBlnJ9M_bXXKck16qjoInYIRo0xFp5pPPU`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };
  return (
    <div className="mt-md-5">
      <Container>
        {loading && (
          <div className="LoaderDiv">
            <LoadingSpinner />
          </div>
        )}
        <div className='d-flex justify-content-end'>
          <ShareIcon url={url} hasInvite={true} />
        </div>
        <Row className="d-flex justify-content-between">
          <Col xs={12} md={4} className='consultnant-profile-card'>

            <ConsultantCard
              consultant={item}
              // specs={
              //   <Fragment>
              //     <div
              //       className="d-flex align-items-center mt-4 d-none"
              //       style={{
              //         justifyContent: 'space-evenly',
              //         borderBottom: '1px solid #eee',
              //         paddingBottom: '20px',
              //       }}>
              //       <div>
              //         <FaClock color="#fb5465" style={{fontSize: '1.5rem'}} />{' '}
              //         30 Min
              //       </div>

              //       <div>
              //         <FaDollarSign
              //           color="#65c890"
              //           style={{fontSize: '1.5rem'}}
              //         />{' '}
              //         20 $
              //       </div>
              //     </div>
              //     <div className="mt-3">
              //       {/*<p
              //                           style={{
              //                               color: '#fb5465',
              //                               fontWeight: '600'
              //                           }}
              //                       >
              //                           Ahmed Completed 30 Successful Session
              //                       </p>*/}
              //       <MainButton text="Request Consultation" />
              //     </div>
              //   </Fragment>
              // }
            />
            {tab == 'sessions' && (
              <div className="consultant-actions-btns">
                <div>
                  <ConsultantSessionsBtnActions
                    consId={id!}
                    showFree={
                      item?.first_session_free !== 0 &&
                      item?.consultation_info_disable == 0
                    }
                    enableFree={
                      item?.available_free_sessions! > 0 &&
                      item?.first_session_free !== 2
                    }
                    item={item}
                    firstFree={item?.first_session_free!}
                    availableFreeSessions={item?.available_free_sessions!}
                    navToFree={() => {
                      navigate('/consultants/sessions/free', {
                        state: {consultant: item},
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </Col>

          <Col xs={12} md={7} className='flex-grow-1'>
            <div className="mb-3 d-flex justify-content-between align-items-center">
              {item
              ? 
              <ConsultantTabs
                consultant={item}
                survey={survey}
                setTab={setTab}
              />
              : '' }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Consultant;
