export const getDynamicLinkDomain = () => {
  
    let domainName =''
    let hostName = window.location.hostname

    if( hostName == 'localhost' || hostName == 'championsdev.championsacademy.ca' ){
        domainName = 'https://championsdev.championsacademy.ca'
        
        
    }

    else if( hostName == 'championstest.championsacademy.ca' ){
        domainName = 'https://championstest.championsacademy.ca'
    }

    else if(hostName == 'championsprod.championsacademy.ca' || hostName == 'championsacademy.ca'){
        domainName = 'https://championsacademy.ca'
    }
    
    else{
        domainName = 'https://championsdev.championsacademy.ca'
    }
    
    
    return domainName
  
};
