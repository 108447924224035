import { useEffect, useState } from 'react'
import classes from '../style/ActivitiesTab.module.css'
import { Alert, Card, Col, Container, Nav, Row } from 'react-bootstrap'
import CircleProgress from '../../../UI/circle-progress/CircleProgress';
import { FaRegCheckSquare } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getDomain } from '../../../helpers/getDomain';
import { colors } from '../../../constants/colors';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const RankingTab = ({userId,hasInvite,trackType,hasRanking} : any ) => {
    const [rankingList, setRankingList] = useState([]);
    const [rankingActivityList, setRankingActivityList] = useState([]);
    const [rankingInvitationList, setRankingInvitationList] = useState([]);
    const {id} = useParams();
    
    

    const getMembers = async (order='total') => {
        
        let token = localStorage.getItem('token')
        //let userId = localStorage.getItem('id')
    
        let currentUserId = userId
        try {
          let result = await axios({
            url:
              
              `${getDomain()}/api/trackingsystem/get/user/trackings/${id}/general/ranking?current_user_id=${currentUserId}&order_by=${order}`,
            method: 'get',
    
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('rankList', result.data.data);
          if (result.data.status) {
            if(order == 'total' ){
              setRankingList(result.data.data);
            }else if( order == 'activity' ){
              setRankingActivityList(result.data.data);
            }else{
              setRankingInvitationList(result.data.data);
            }
          }
        } catch (error) {
          console.log('gugu', error);
        }
      };

      useEffect(() => {
        
        if(hasInvite ){
          getMembers('total');
        }
        getMembers('activity');
        getMembers('invitation');
      }, []);
   
    

  return (
    <>
     
    <Container className='containers-tab child-tabs mt-4'>

      {
        !hasRanking && <Alert className='mt-3' variant='danger'>
        Ranking will be available once the challenge ends and submissions are reviewed.
        </Alert>
      }

      <Tab.Container id="left-tabs-example" defaultActiveKey={ hasInvite ? 'total' : 'activities' }>
        <Row>
          <Col sm={12} className='containers-tab'>
            <Nav variant="pills">

              {(hasRanking && rankingList.length) ? 
                <Nav.Item>
                  <Nav.Link eventKey="total">Total</Nav.Link>
                </Nav.Item>
              : '' }
              
              {(hasRanking && rankingActivityList.length) ?
                <Nav.Item>
                  <Nav.Link eventKey="activities">Activity</Nav.Link>
                </Nav.Item>
              : '' }
              {(hasRanking && rankingInvitationList.length && trackType == 'initiative') ? 
                <Nav.Item>
                  <Nav.Link eventKey="invitations">Invitations</Nav.Link>
                </Nav.Item>
              : '' }
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>

              { (rankingList.length) ?   
              <Tab.Pane eventKey="total">
                <Row>
                  <Col md="12" className="mt-3 mb-0">
                    <h3 className='points-title'>Subscirbers Namber  <span className='points-val'>({rankingList.length}) </span></h3>
                  </Col>   
                  {rankingList.map((item:any, i) => {
                      return (
                      <Col md="12" className='mt-4'>   
                          <Card style={{ padding: '0rem', overflow: 'hidden' }}>
                              <div className='d-flex justify-content-between align-items-center'  >
                                  <div className='d-flex align-items-center justify-content-center'>

                                      {hasRanking ? <>

                                        { item.rank == 1 ? 
                                        <div className={`rank-details first-rank`}>
                                          <img src='/gold.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank == 2 ? 
                                        <div className={`rank-details second-rank`}>
                                          <img src='/silver.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank == 3 ? 
                                        <div className={`rank-details third-rank`}>
                                          <img src='/bronze.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank > 3 ?    
                                        <div className={`rank-details normal-rank`}>
                                          
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                      </> : '' }
                                      
                                      <span style={{ position: 'relative' , margin: '10px 20px', }}>
                                          <img src='/icons/man.png' style={{ borderRadius: '50%' , width: '60px' }} />
                                      </span>
                                      <span
                                      className='ranking-name'
                                      style={{
                                          fontSize: '20px',
                                          fontWeight: '500',
                                          color: item?.me ? colors.orange : '#000'
                                      }}  
                                      >{item?.name} {item?.me ? '(You)' : '' } </span>
                                  </div>
                                  {hasRanking ? 
                                  <div className='mx-2' style={{ scale: '.8' }}>
                                      <CircleProgress 
                                      progressColor='#e37e08'
                                      background='#fff4e8'
                                      titleColor='#e37e08'
                                      value={ item.score ? ((item.score == 100.00) ? 100 : parseFloat(item.score).toFixed(1)) : 0 }/>
                                  </div>
                                  : '' }
                              </div>

                          </Card>
                      </Col>

                  );
                  })}
                </Row>
              </Tab.Pane>
              : '' }

              { (rankingActivityList.length) ?   
              <Tab.Pane eventKey="activities">
                <Row>
                  <Col md="12" className="mt-3 mb-0">
                    <h3 className='points-title'>Subscirbers Namber  <span className='points-val'>({rankingActivityList.length}) </span></h3>
                  </Col> 
                  {rankingActivityList.map((item:any, i) => {
                      return (
                        <Col md="12" className='mt-4'>   
                        <Card style={{ padding: '0rem', overflow: 'hidden' }}>
                            <div className='d-flex justify-content-between align-items-center'  >
                                <div className='d-flex align-items-center justify-content-center'>

                                      {hasRanking ? <>
                                      
                                        { item.rank == 1 ? 
                                        <div className={`rank-details first-rank`}>
                                          <img src='/gold.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank == 2 ? 
                                        <div className={`rank-details second-rank`}>
                                          <img src='/silver.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank == 3 ? 
                                        <div className={`rank-details third-rank`}>
                                          <img src='/bronze.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank > 3 ?    
                                        <div className={`rank-details normal-rank`}>
                                          
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }
                                    
                                      </> : '' }

                                    <span style={{ position: 'relative' , margin: '10px 20px', }}>
                                        <img src='/icons/man.png' style={{ borderRadius: '50%' , width: '60px' }} />
                                    </span>
                                    <span
                                    className='ranking-name'
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        color: item?.me ? colors.orange : '#000'
                                    }}  
                                    >{item?.name} {item?.me ? '(You)' : '' } </span>
                                </div>
                                {hasRanking ? 
                                <div className='mx-2' style={{ scale: '.8' }}>
                                <CircleProgress 
                                  value={ item.score ? ((item.score == 100.00) ? 100 : parseFloat(item.score).toFixed(1)) : 0 }
                                  progressColor='#e37e08'
                                  background='#fff4e8'
                                  titleColor='#e37e08'
                                />
                                </div>
                                : '' }
                            </div>

                        </Card>
                    </Col>

                  );
                  })}
                </Row>
              </Tab.Pane>
              : '' }

              { (rankingInvitationList.length && trackType == 'initiative' ) ?  
              <Tab.Pane eventKey="invitations">
                <Row>
                  <Col md="12" className="mt-3 mb-0">
                    <h3 className='points-title'>Subscirbers Namber  <span className='points-val'>({rankingInvitationList.length}) </span></h3>
                  </Col> 
                  {rankingInvitationList.map((item:any, i) => {
                      return (
                        <Col md="12" className='mt-4'>   
                        <Card style={{ padding: '0rem', overflow: 'hidden' }}>
                            <div className='d-flex justify-content-between align-items-center'  >
                                <div className='d-flex align-items-center justify-content-center'>

                                      {hasRanking ? <>

                                        { item.rank == 1 ? 
                                        <div className={`rank-details first-rank`}>
                                          <img src='/gold.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank == 2 ? 
                                        <div className={`rank-details second-rank`}>
                                          <img src='/silver.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank == 3 ? 
                                        <div className={`rank-details third-rank`}>
                                          <img src='/bronze.svg' />
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                        { item.rank > 3 ?    
                                        <div className={`rank-details normal-rank`}>
                                          
                                          <div className='rank'>{item.rank}</div>
                                        </div>
                                        : '' }

                                      </> : '' }
                                    
                                    <span style={{ position: 'relative' , margin: '10px 20px', }}>
                                        <img src='/icons/man.png' style={{ borderRadius: '50%' , width: '60px' }} />
                                    </span>
                                    <span
                                    className='ranking-name'
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: '500',
                                        color: item?.me ? colors.orange : '#000'
                                    }}  
                                    >{item?.name} {item?.me ? '(You)' : '' } </span>
                                </div>
                                {hasRanking ? 
                                <div className='mx-2' style={{ scale: '.8' }}>
                                <CircleProgress 
                                progressColor='#e37e08'
                                background='#fff4e8'
                                titleColor='#e37e08'
                                value={ item.score ? ((item.score == 100.00) ? 100 : parseFloat(item.score).toFixed(1)) : 0 }/>
                                </div>
                                : '' }
                            </div>

                        </Card>
                    </Col>

                  );
                  })}
                </Row>
              </Tab.Pane>
              : '' }

            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

    </Container>
    
    </>
  );
}

export default RankingTab;
