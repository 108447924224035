import {Collapse} from 'react-bootstrap';
import {FaArrowUp,FaArrowDown, FaPlus, FaMinus} from 'react-icons/fa';
import React, {useState} from 'react';
import { LikeQuestion, WatchQuestion } from '../../Screens/BankQuestions/Bank.service';

const BankQuestionItem = (props) => {
  
  const [isCollapsed, setIsCollapsed] = useState(props.collapsed);

  const Like = async (id, like) => {
    let data = new FormData();
    data.append(`like`, like ? '1' : '0');
    let result = await LikeQuestion(data, id);

    if (result.response.status) {
      console.log('loadQuestions', result.response);
      props.loadQuestions();
    }
  };

  const Watch = async (id) => {
    let result = await WatchQuestion(id);

    if (result.response.status) {
      console.log('watch', result.response);
      props.loadQuestions();
    }
  };


  return (
    <div className="section-container">
      <div
        onClick={() => {
          setIsCollapsed(!isCollapsed)
          if(props.isLoggedIn){
            Watch(props.id)
          }

        }}
        aria-controls="example-collapse-text"
        aria-expanded={props.collapsed}
        className="d-flex align-items-center section-item-collapse justify-content-between bankquestionItem">
        <div className="d-flex align-items-center first-section">
          <span className="question-icon">
            <img src="/icons/quiz.png" />
          </span>
          <p className="section-title">{props.question}</p>
        </div>

        <div className="d-flex">
          <div className="d-flex align-items-center bankQuestionRates">
            <div className="m-2">
              <img src="/icons/open-eye.png" alt="img" />
              <span className="m-2">{props.views}</span>
            </div>

            <div className="m-2" onClick={() => {
              if(props.isLoggedIn){
                Like(props.id, 1)
              }
            }}>
              {props.is_liked ? (
                <img
                  src="/icons/activelike.png"
                  alt="img"
                  width={24}
                />
              ) : (
                <img src="/icons/like.png" alt="img" />
              )}
              <span className="m-2">{props.likes}</span>
            </div>

            <div className="m-2" onClick={() => {
              if(props.isLoggedIn){
                Like(props.id, 0)
              }
            }}>
              {props.is_disliked ? (
                <img
                  src="/icons/activedislike.png"
                  alt="img"
                  style={{transform: 'rotate(180deg)'}}
                />
              ) : (
                <img src="/icons/dislike.png" alt="img" />
              )}
              <span className="m-2">{props.dislikes}</span>
            </div>
          </div>

          <div className="collaped-item m-2">
            {isCollapsed ? <FaMinus /> : <FaPlus /> }
          </div>
        </div>
      </div>
      <Collapse in={isCollapsed}>
        <div id="example-collapse-text">
          <div className="section-item d-flex justify-content-between">
            <div style={{padding: '0 2rem'}}>
              {props.answer}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
      
  );
};

export default BankQuestionItem;
